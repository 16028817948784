import { useCallback } from "react";
import { getFirestore, doc, updateDoc } from "firebase/firestore";

const useUpdateQuiz = () => {
    const updateQuizInFirebase = useCallback(async (quizId, quizTitle, questions, optionalText) => {
        try {
            const db = getFirestore();
            const quizRef = doc(db, 'quizzes', quizId);
            await updateDoc(quizRef, {
                title: quizTitle,
                questions: questions,
                optionalText: optionalText // Update the optional text
            });
            return { success: true };
        } catch (error) {
            console.error("Error updating quiz:", error);
            return { success: false, error: error.message };
        }
    }, []);

    return { updateQuizInFirebase };
};

export default useUpdateQuiz;
