import { useCallback } from "react";
import { getFirestore, doc, deleteDoc } from "firebase/firestore";

const useDeleteQuizResult = () => {
    const deleteQuizResultFromFirebase = useCallback(async (quizResultId) => {
        try {
            const db = getFirestore();
            const docRef = doc(db, 'quizResults', quizResultId);
            await deleteDoc(docRef);
            return { success: true };
        } catch (error) {
            console.error("Error deleting quiz result:", error);
            return { success: false, error: error.message };
        }
    }, []);

    return { deleteQuizResultFromFirebase };
};

export default useDeleteQuizResult;
