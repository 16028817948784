import React, {useState} from 'react';
import {Avatar, Box, Button, Card, Snackbar, TextField, Typography} from '@mui/material';
import {useAuthCheck} from "../../hooks/auth/UseAuthCheck";
import QuestionComponent from "../../components/quiz/admin/QuestionComponent";
import useSaveQuiz from '../../hooks/quiz/UseSaveQuiz';
import {useNavigate} from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

const initialQuestion = {questionText: '', options: ['', ''], correctAnswers: []};
const QuizCreationPage = () => {
    const {isAdmin} = useAuthCheck();
    const navigate = useNavigate();
    const [quizTitle, setQuizTitle] = useState('');
    const [questions, setQuestions] = useState([initialQuestion]);
    const {saveQuizToFirebase} = useSaveQuiz();
    const [optionalText, setOptionalText] = useState('');
    const [snackbar, setSnackbar] = useState({open: false, message: '', severity: 'success'});
    if (!isAdmin) return <Typography>Doar profesorii pot accesa această pagină.</Typography>;

    const handleQuizTitleChange = (e) => {
        setQuizTitle(e.target.value);
    };

    const handleQuestionChange = (index, updatedQuestion) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index] = updatedQuestion;
        setQuestions(updatedQuestions);
    };

    const addQuestion = () => {
        if (questions.length < 20) {
            setQuestions([...questions, initialQuestion]);
        }
    };

    const saveQuiz = async () => {
        if (!validateQuiz()) {
            setSnackbar({
                open: true,
                message: 'Completați toate câmpurile și asigurați-vă că fiecare întrebare are cel puțin un răspuns corect.',
                severity: 'error'
            });
            return;
        }
        const result = await saveQuizToFirebase(quizTitle, questions, optionalText);

        if (result.success) {
            setSnackbar({open: true, message: 'Quiz-ul a fost salvat cu succes!', severity: 'success'});
            navigate("/");
        } else {
            console.error("Error saving quiz:", result.error);
            setSnackbar({open: true, message: 'Eroare la salvarea quiz-ului.', severity: 'error'});
        }
    };

    const deleteQuestion = (index) => {
        const updatedQuestions = [...questions];
        updatedQuestions.splice(index, 1);
        setQuestions(updatedQuestions);
    };

    const validateQuiz = () => {
        if (quizTitle === '') return false;
        for (const question of questions) {
            if (question.questionText === '' || question.correctAnswers.length === 0 || question.options.some(opt => opt === '')) {
                return false;
            }
        }
        return true;
    };

    return (
        <Box p={4}>
            <b>Completați acest câmp doar dacă doriți adăugarea unui text suport!</b>
            <TextField
                label="Text opțional"
                fullWidth
                multiline
                rows={4}
                value={optionalText}
                onChange={(e) => setOptionalText(e.target.value)}
                style={{marginBottom:20}}
            />
            <TextField label="Titlul Quiz-ului" fullWidth value={quizTitle} onChange={handleQuizTitleChange}/>
                {questions.map((q, index) => (
                    <Card key={index} variant="outlined" style={{ marginBottom: '20px', padding: '10px', position: 'relative' }}>
                        <h3><b>Întrebarea {index +1}</b></h3>
                        <Box style={{ position: 'relative' }}>
                            <QuestionComponent
                                question={q}
                                questionNumber={index + 1}
                                onQuestionChange={(updatedQuestion) => handleQuestionChange(index, updatedQuestion)}
                            />
                            <Button onClick={addQuestion} disabled={questions.length >= 20} startIcon={<AddIcon/>}>
                                Adăugați o întrebare
                            </Button>
                            <Button onClick={() => deleteQuestion(index)} startIcon={<DeleteIcon/>} variant="text"
                                    size="small" style={{marginTop: '10px', marginBottom: '10px', color: 'grey'}}>
                                Șterge Întrebarea
                            </Button>
                        </Box>
                    </Card>
                ))}
            <Button variant="contained" color="primary" onClick={saveQuiz} startIcon={<SaveIcon/>}>
                Salvați Quiz-ul
            </Button>
                <Snackbar
                    open={snackbar.open}
                    onClose={() => setSnackbar((prev) => ({...prev, open: false}))}
                    message={snackbar.message}
                    autoHideDuration={4000}
                    severity={snackbar.severity}
                />
        </Box>
    );
};

export default QuizCreationPage;
