import { useState, useEffect } from 'react';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

export const useCheckHomeworkStatus = (title, userEmail) => {
    const [loading, setLoading] = useState(true);
    const [homeworkSubmitted, setHomeworkSubmitted] = useState(false);

    useEffect(() => {
        const fetchStatus = async () => {
            const db = getFirestore();
            const homeworkCollection = collection(db, 'homeworksSolutions');
            const q = query(homeworkCollection, where("title", "==", title), where("userEmail", "==", userEmail));
            const querySnapshot = await getDocs(q);

            if (querySnapshot.size > 0) {
                setHomeworkSubmitted(true);
            }
            setLoading(false);
        };

        fetchStatus();
    }, [title, userEmail]);

    return { loading, homeworkSubmitted };
};
