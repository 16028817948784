import React, { useState } from 'react';
import {Link as RouterLink} from 'react-router-dom';
import useSignOut from '../../hooks/auth/UseSignOut';
import {useAuthCheck} from "../../hooks/auth/UseAuthCheck";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddBoxIcon from '@mui/icons-material/AddBox';
import PostAddIcon from '@mui/icons-material/PostAdd';
import CreateIcon from '@mui/icons-material/Create';
import {
    AppBar,
    Toolbar,
    Button,
    Menu,
    MenuItem,
    Box,
    Avatar,
    Tooltip,
    Badge,
    IconButton,
    ListItemIcon,
    DialogContentText, DialogContent, DialogTitle, Dialog
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import useGetUserNotifications from "../../hooks/notifications/UseGetUserNotifications";
import useDeleteUserNotifications from "../../hooks/notifications/UseDeleteUserNotification";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const computeColor = (score) => {
    // Interpolează între roșu și verde pe baza scorului
    const red = 255 - Math.round((score / 10) * 255);
    const green = Math.round((score / 10) * 255);
    return `rgb(${red}, ${green}, 0)`;
};

const Navbar = () => {
    const { user, isAdmin, isLoading } = useAuthCheck();
    const { handleSignOut, isLoading: signOutLoading, error: signOutError } = useSignOut();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(null);
    const userNotifications = useGetUserNotifications(user?.email);
    const deleteUserNotifications = useDeleteUserNotifications();
    const [profileAnchorEl, setProfileAnchorEl] = useState(null); // Adăugat pentru meniul profilului
    const handleProfileClick = (event) => {
        setProfileAnchorEl(event.currentTarget);
    };

    const handleProfileClose = () => {
        setProfileAnchorEl(null);
    };
    const handleNotificationClick = (notif) => {
        setSelectedNotification(notif);
        setOpenDialog(true);
    };

    const clearAllNotifications = async () => {
        if(user?.email) {
            await deleteUserNotifications(user.email);
            userNotifications.length = 0; // Golim array-ul notificărilor
        }
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSignOutWrapper = async () => {
        await handleSignOut();
        handleClose();
    };
    const [notifAnchorEl, setNotifAnchorEl] = useState(null);
    const handleNotifClick = (event) => {
        setNotifAnchorEl(event.currentTarget);
    };
    const handleNotifClose = () => {
        setNotifAnchorEl(null);
    };

    if (isLoading || signOutLoading) {
        return <AppBar position="static"><Toolbar>Încărcare...</Toolbar></AppBar>;
    }

    return (
        <AppBar position="static">
            <Toolbar>
                <Box display="flex" flexGrow={1}>
                    <Button color="inherit" component={RouterLink} to="/">Acasă</Button>

                    {/* Popup cu opțiunile Materiale, Teme și Teste */}
                    <Button color="inherit" aria-haspopup="true" onClick={handleClick}>
                        Resurse
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem component={RouterLink} to="/materiale" onClick={handleClose}>
                            Materiale
                        </MenuItem>
                        <MenuItem component={RouterLink} to="/teme" onClick={handleClose}>
                            Teme
                        </MenuItem>
                        <MenuItem component={RouterLink} to="/teste" onClick={handleClose}>
                            Teste
                        </MenuItem>
                    </Menu>
                </Box>

                <Box display="flex">
                    {user ? (
                        <>
                            <Tooltip title="Notificări" placement="bottom">
                                <Badge
                                    badgeContent={userNotifications.length}
                                    color="error"
                                    onClick={handleNotifClick}
                                    style={{ marginRight: '10px' }}  // Adăugat distanță între notificări și profil
                                >
                                    <Avatar>🔔</Avatar>
                                </Badge>
                            </Tooltip>

                            <Menu
                                anchorEl={notifAnchorEl}
                                keepMounted
                                open={Boolean(notifAnchorEl)}
                                onClose={handleNotifClose}
                            >
                                {userNotifications.length ? (
                                    <>
                                        {userNotifications.map(notif => (
                                            <MenuItem key={notif.id} onClick={() => handleNotificationClick(notif)}>
                                                <Box
                                                    style={{
                                                        backgroundColor: computeColor(notif.score),
                                                        borderRadius: '50%',
                                                        width: '24px',
                                                        height: '24px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        marginRight: '8px'
                                                    }}
                                                >
                                                    {notif.score}
                                                </Box>
                                                {notif.tema}
                                            </MenuItem>
                                        ))}
                                        <MenuItem>
                                            <ListItemIcon>
                                                <IconButton onClick={clearAllNotifications}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </ListItemIcon>
                                        </MenuItem>
                                    </>
                                ) : (
                                    <MenuItem disabled>Momentan nu ai notificări</MenuItem>
                                )}
                            </Menu>

                            <Tooltip title={user?.displayName || 'Utilizator'} placement="bottom">
                                <Avatar onClick={handleProfileClick}>
                                    {user?.email ? user.email[0].toUpperCase() : ''}
                                </Avatar>
                            </Tooltip>

                            <Menu
                                anchorEl={profileAnchorEl}
                                keepMounted
                                open={Boolean(profileAnchorEl)}
                                onClose={handleProfileClose}
                            >
                                <MenuItem component={RouterLink} to="/profil" onClick={handleProfileClose}>
                                    <ListItemIcon>
                                        <AccountCircleIcon fontSize="small" />
                                    </ListItemIcon>
                                    Profil
                                </MenuItem>
                                <MenuItem onClick={handleSignOutWrapper}>
                                    <ListItemIcon>
                                        <ExitToAppIcon fontSize="small" />
                                    </ListItemIcon>
                                    Deconectează-te
                                </MenuItem>
                                {isAdmin && (
                                    <>
                                        <MenuItem
                                            component={RouterLink}
                                            to="/verifica-teme"
                                            onClick={handleProfileClose}
                                        >
                                            <ListItemIcon>
                                                <CheckCircleIcon fontSize="small" />
                                            </ListItemIcon>
                                            Verifică Temele
                                        </MenuItem>
                                        <MenuItem
                                            component={RouterLink}
                                            to="/verifica-teste"
                                            onClick={handleProfileClose}
                                        >
                                            <ListItemIcon>
                                                <CheckCircleIcon fontSize="small" />
                                            </ListItemIcon>
                                            Verifică teste
                                        </MenuItem>
                                        <MenuItem
                                            component={RouterLink}
                                            to="/adaugă-temă"
                                            onClick={handleProfileClose}
                                        >
                                            <ListItemIcon>
                                                <AddBoxIcon fontSize="small" />
                                            </ListItemIcon>
                                            Adaugă o temă
                                        </MenuItem>
                                        <MenuItem
                                            component={RouterLink}
                                            to="/adaugă-postare"
                                            onClick={handleProfileClose}
                                        >
                                            <ListItemIcon>
                                                <PostAddIcon fontSize="small" />
                                            </ListItemIcon>
                                            Adaugă un material
                                        </MenuItem>
                                        <MenuItem
                                            component={RouterLink}
                                            to="/crează-test"
                                            onClick={handleProfileClose}
                                        >
                                            <ListItemIcon>
                                                <CreateIcon fontSize="small" />
                                            </ListItemIcon>
                                            Adaugă un test
                                        </MenuItem>
                                    </>
                                )}
                            </Menu>
                        </>
                    ) : (
                        <Button color="inherit" component={RouterLink} to="/login">
                            Logare
                        </Button>
                    )}
                </Box>
            </Toolbar>
            {signOutError && (
                <Box bgcolor="error.main" color="error.contrastText" p={2} textAlign="center">
                    {signOutError}
                </Box>
            )}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                {selectedNotification && (
                    <>
                        <DialogTitle>{selectedNotification.tema}</DialogTitle>
                        <DialogContent>
                            <Box
                                style={{
                                    backgroundColor: computeColor(selectedNotification.score),
                                    borderRadius: '50%',
                                    width: '50px',
                                    height: '50px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    margin: '0 auto'
                                }}
                            >
                                {selectedNotification.score}
                            </Box>
                            <DialogContentText>
                                {selectedNotification.text}
                            </DialogContentText>
                        </DialogContent>
                    </>
                )}
            </Dialog>
        </AppBar>
    );
};

export default Navbar;
