import React, {useState} from 'react';
import {
    Card,
    CardContent,
    Typography,
    Button,
    TextField,
    Box,
    MenuItem,
    Select,
    Snackbar,
    Tooltip, CardMedia, Collapse, Dialog, DialogContent
} from '@mui/material';
import { useUploadHomeworkRating } from "../../hooks/homeworks/UseUploadHomeworkRating";
import useCheckFeedbackStatus from "../../hooks/homeworks/UseCheckFeedbackStatus";
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import useGetUserName from "../../hooks/auth/UseGetUserName";
import {useAuthCheck} from "../../hooks/auth/UseAuthCheck";


const HomeworkComponent = ({ homework }) => {
    const { rating, comment, setRating, setComment, saveRating, setEmail, error, successMessage,setError,setSuccessMessage } = useUploadHomeworkRating(homework.id, homework.title);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const { feedbackSubmitted, isLoading: isFeedbackLoading } = useCheckFeedbackStatus(homework.title, homework.userEmail);
    const [openDialog, setOpenDialog] = useState(false);
    const { userName, loading: userLoading } = useGetUserName(homework.userEmail);

    const handleImageClick = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const handleSaveRating = () => {
        saveRating(homework.userEmail);
        setSnackbarOpen(true);
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    if (isFeedbackLoading) {
        return <div>Verificare feedback...</div>;
    }
    return (
        <Card style={{ marginBottom: '30px', padding: '20px' }}>
            <CardContent>
                <Typography variant="h6" gutterBottom align="center">
                    Titlu: {homework.title}
                </Typography>

                <Typography variant="body1" align="center">
                    Cerință: {homework.requirement}
                </Typography>

                <Typography variant="body1" align="center" style={{ marginTop: '15px' }}>
                    Trimis de: {userLoading ? "Loading..." : (userName || homework.userEmail)}
                </Typography>

                {homework.downloadURL && (
                    <Box position="relative" display="flex" flexDirection="column" alignItems="center" marginTop={2}>
                        <CardMedia
                            component="img"
                            height="140"
                            width="100"
                            image={homework.downloadURL}
                            alt={homework.title}
                            style={{
                                cursor: 'pointer',
                                transition: 'transform 0.3s',
                                boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
                                marginBottom: '20px'
                            }}
                            onClick={handleImageClick}
                        />

                        <Tooltip title="Mărește imaginea">
                            <IconButton
                                style={{
                                    position: 'absolute',
                                    top: '5px',
                                    right: '5px',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    color: 'white',
                                    transition: 'opacity 0.3s'
                                }}
                                sx={{
                                    '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.7)'
                                    }
                                }}
                            >
                                <ZoomInIcon />
                            </IconButton>
                        </Tooltip>

                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <Box display="flex" justifyContent="center" alignItems="center" marginBottom={2}>
                                <Typography variant="body1">
                                    Notă:
                                </Typography>
                                <Select
                                    value={rating}
                                    onChange={(e) => setRating(e.target.value)}
                                    style={{ marginLeft: '10px', width: '70px' }}
                                >
                                    {[...Array(10).keys()].map(num =>
                                        <MenuItem key={num + 1} value={num + 1}>{num + 1}</MenuItem>
                                    )}
                                </Select>
                            </Box>

                            <Box display="flex" flexDirection="column" alignItems="center" marginTop={2}>
                                <Typography variant="body1">
                                    Comentariu:
                                </Typography>
                                <TextField
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    style={{ marginTop: '10px', width: '80%' }}
                                    multiline
                                    rows={4}
                                />
                            </Box>

                            <Box display="flex" justifyContent="center" alignItems="center" marginTop={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSaveRating}
                                >
                                    {(successMessage || feedbackSubmitted) ? "Recorectează Tema" : "Corectează Tema"}
                                </Button>
                            </Box>
                        </Collapse>

                        <IconButton
                            style={{ marginTop: '15px' }}
                            onClick={handleExpandClick}>
                            <ExpandMoreIcon style={{ transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)' }}/>
                        </IconButton>
                    </Box>
                )}
            </CardContent>

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                maxWidth="md"
                fullWidth
            >
                <DialogContent>
                    <img src={homework.downloadURL} alt={homework.title} style={{ width: '100%' }} />
                </DialogContent>
            </Dialog>

            {error && (
                <Snackbar
                    open={!!error}
                    autoHideDuration={5000}
                    onClose={() => setError(null)}
                    message={error}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                />
            )}

            {successMessage && (
                <Snackbar
                    open={!!successMessage}
                    autoHideDuration={5000}
                    onClose={() => setSuccessMessage(null)}
                    message={successMessage}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                />
            )}
        </Card>
    );




};

export default HomeworkComponent;
