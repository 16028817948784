import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import HomeworkComponent from "../../components/homework/HomeworkComponent";
import useFetchSubmittedHomeworks from "../../hooks/homeworks/UseFetchSubmittedHomeworks";

const HomeworkCheckPage = () => {
    const { submittedHomeworks, isLoading } = useFetchSubmittedHomeworks();

    if (isLoading) {
        return <div>Încărcare teme...</div>; // Poti adăuga aici o componentă de încărcare dacă dorești
    }

    return (
        <Container>
            <Box mt={4}>
                <Typography variant="h4" gutterBottom>
                    Teme Trimise
                </Typography>
                {submittedHomeworks.map(theme => (
                    <HomeworkComponent key={theme.id} homework={theme} />
                ))}
            </Box>
        </Container>
    );
};

export default HomeworkCheckPage;
