import React from 'react';
import useQuizResults from "../../hooks/quiz/UseQuizResults";
import UserCard from "../../components/quiz/admin/UserCard";
import useDeleteQuizResult from "../../hooks/quiz/UseDeleteQuizResult";

const UsersPage = () => {
    const { usersData, loading,setUsersData } = useQuizResults();
    const { deleteQuizResultFromFirebase } = useDeleteQuizResult();

    const removeQuizResultFromState = (quizResultId) => {
        const updatedUsersData = usersData.map(user => {
            // Filtrează rezultatele quizului pentru a exclude rezultatul șters
            const filteredQuizResults = user.quizResults.filter(result => result.id !== quizResultId);
            return { ...user, quizResults: filteredQuizResults };
        });
        setUsersData(updatedUsersData);
    };

    const handleDelete = async (quizResultId) => {
        const result = await deleteQuizResultFromFirebase(quizResultId);
        if (result.success) {
            // Actualizați starea pentru a reflecta ștergerea
            removeQuizResultFromState(quizResultId);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h1>Users</h1>
            {loading ? (
                <div>Loading...</div>
            ) : (
                usersData.map(user => (
                    <UserCard key={user.userId} user={user} onDelete={handleDelete} />
                ))
            )}
        </div>
    );
};

export default UsersPage;
