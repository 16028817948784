import { useState } from 'react';
import { loginUser } from "../../services/LoginUser";
import { useNavigate } from 'react-router-dom';

const useLogin = (initialUsername = '', initialPassword = '') => {
    const [username, setUsername] = useState(initialUsername);
    const [password, setPassword] = useState(initialPassword);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const history = useNavigate();

    const onSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage('');
        setErrorMessage('');

        if (!username || !password) {
            setErrorMessage('Email-ul și parola sunt obligatorii.');
            setOpenSnackbar(true);
            return;
        }

        try {
            const user = await loginUser(username, password); // login și returnează user-ul
            setSuccessMessage('Autentificare reușită!');
            setOpenSnackbar(true);
            history('/');
        } catch (error) {
            setErrorMessage('Email-ul introdus sau parola sunt greșite!');
            setOpenSnackbar(true);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return {
        username,
        setUsername,
        password,
        setPassword,
        errorMessage,
        successMessage,
        openSnackbar,
        onSubmit,
        handleCloseSnackbar,
    };
};

export default useLogin;
