import React, { useState, useEffect } from 'react';
import { Button, Container, TextField, Typography, Box, CircularProgress } from '@mui/material';
import usePasswordReset from "../../hooks/auth/UsePasswordResetNoAuth";

const ForgotPasswordPage = () => {
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isResetSent, setIsResetSent] = useState(false);
    const [cooldown, setCooldown] = useState(false);
    const { handlePasswordReset, error, success, resetState } = usePasswordReset();

    const handleSubmit = async () => {
        if(!email){
            resetState("Introdu adresa de e-mail!");
            return;
        }

        setIsLoading(true);
        await handlePasswordReset(email);
        setIsLoading(false);
        setIsResetSent(true);

        // Set cooldown for 60 seconds
        setCooldown(true);
        setTimeout(() => {
            setCooldown(false);
            setIsResetSent(false); // Allow the user to send another reset after cooldown
        }, 60000);
    };

    return (
        <Container>
            <Box mt={4}>
                <Typography variant="h4" gutterBottom align="center">
                    Am uitat parola
                </Typography>
                <Typography variant="body1" gutterBottom align="center">
                    Introdu adresa de e-mail asociată contului tău pentru a primi instrucțiuni de resetare a parolei.
                </Typography>
                <TextField
                    label="Adresa de e-mail"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    margin="normal"
                />
                <Box mt={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={isLoading || cooldown}
                        startIcon={isLoading ? <CircularProgress size={24} /> : null}
                    >
                        {isResetSent ? "Retrimite solicitare" : "Trimite solicitare"}
                    </Button>
                </Box>
                {error && <Typography color="error">{error}</Typography>}
                {success && <Typography color="primary">Un email a fost trimis către {email} pentru resetarea parolei!</Typography>}
            </Box>
        </Container>
    );
};

export default ForgotPasswordPage;
