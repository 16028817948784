import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import Navbar from "./components/utils/Navbar";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/login/LoginPage";
import './firebase';
import ProfilePage from "./pages/profile/ProfilePage";
import MaterialsPage from "./pages/materials/MaterialsPage";
import HomeworkCheckPage from "./pages/homework/HomeworkCheckPage";
import ProtectedRoute from "./components/utils/ProtectedRoute";
import HomeworksPage from "./pages/homework/HomeworksPage";
import QuizCreationPage from "./pages/quiz/QuizCreationPage";
import ViewQuizzesPage from "./pages/quiz/ViewQuizzesPage";
import QuizPage from "./pages/quiz/QuizPage";
import AddPostPage from "./pages/admin/AddPostPage";
import AddHomeworkPage from "./pages/admin/AddHomeworkPage";
import ForgotPasswordPage from "./pages/login/ForgotPasswordPage";
import UsersPage from "./pages/quiz/UsersPage";

function App() {
    return (
        <Router>
            <Navbar/>
            <Routes>
                {/*EVERYONE*/}
                <Route path="/" element={<HomePage/>}/>
                <Route path="/login" element={<LoginPage/>}/>
                <Route path="/recuperare-parolă" element={<ForgotPasswordPage/>}/>

                {/*LOGGED IN USERS*/}
                <Route
                    path="/profil"
                    element={
                        <ProtectedRoute redirectPath={"/login"}>
                            <ProfilePage/>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/materiale"
                    element={
                        <ProtectedRoute redirectPath={"/login"}>
                            <MaterialsPage/>
                        </ProtectedRoute>
                    }/>
                <Route
                    path="/teme"
                    element={
                        <ProtectedRoute redirectPath={"/login"}>
                            <HomeworksPage/>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/teste"
                    element={
                        <ProtectedRoute redirectPath={"/login"}>
                            <ViewQuizzesPage/>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/test/:quizId"
                    element={
                        <ProtectedRoute redirectPath={"/login"}>
                            <QuizPage/>
                        </ProtectedRoute>
                    }
                />

                {/*ADMINISTRATOR*/}
                <Route
                    path="/verifica-teme"
                    element={
                        <ProtectedRoute redirectPath={"/"} checkAdmin={true} noAdminRedirectPath={"/login"}>
                            <HomeworkCheckPage/>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/verifica-teste"
                    element={
                        <ProtectedRoute redirectPath={"/"} checkAdmin={true} noAdminRedirectPath={"/login"}>
                            <UsersPage/>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/adaugă-temă"
                    element={
                        <ProtectedRoute redirectPath={"/"} checkAdmin={true} noAdminRedirectPath={"/login"}>
                            <AddHomeworkPage/>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/adaugă-postare"
                    element={
                        <ProtectedRoute redirectPath={"/"} checkAdmin={true} noAdminRedirectPath={"/login"}>
                            <AddPostPage/>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/crează-test"
                    element={
                        <ProtectedRoute redirectPath={"/"} checkAdmin={true} noAdminRedirectPath={"/login"}>
                            <QuizCreationPage/>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/administrare-utilizatori"
                    element={
                        <ProtectedRoute redirectPath={"/"} checkAdmin={true} noAdminRedirectPath={"/login"}>
                            <QuizCreationPage/>
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </Router>
    );
}
export default App;
