import React, {useState} from 'react';
import {Card, CardContent, Typography, Avatar, Box, Button} from '@mui/material';
import {getRandomIcon} from '../../utils/IcontUtils';
import useGetQuizResult from "../../../hooks/quiz/UseGetQuizResult";
import useDeleteQuiz from "../../../hooks/quiz/UseDeleteQuiz";
import QuizEditModal from "../admin/QuizEditModal";

const QuizComponent = ({quiz, onClick, email, isAdmin, onDelete}) => {
    const icon = getRandomIcon();
    const {deleteQuizFromFirebase} = useDeleteQuiz(); // Folosiți hook-ul
    // Fetch user score for the given email and quiz id.
    const userScore = useGetQuizResult(email, quiz.id);
    const [openModal, setOpenModal] = useState(false);
    const handleEditQuizClick = () => {
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };
    const handleDeleteClick = async () => {
        const result = await deleteQuizFromFirebase(quiz.id);
        if (result.success) {
            onDelete(quiz.id); // Notify parent component about deletion
        }
    };
    const getColorForScore = (score) => {
        const red = Math.min(255, Math.round(255 - (score * 25.5)));
        const green = Math.min(255, Math.round(score * 25.5));
        return `rgb(${red}, ${green}, 0)`;
    };


    return (
        <Card
            variant="outlined"
            onClick={onClick}
            style={{cursor: 'pointer', transition: 'transform 0.2s', "&:hover": {transform: 'scale(1.05)'}}}
        >
            <Box position="relative">
                {/* Moved score to the top-right corner */}
                <Box position="absolute" top={10} right={10} display="flex" alignItems="center">
                    <Typography variant="body1" style={{marginRight: 8}}>
                        Ai obținut nota:
                    </Typography>
                    <Avatar style={{
                        backgroundColor: userScore !== null ? getColorForScore(userScore) : "#e0e0e0",
                        color: "#333",
                        fontSize: "1rem"
                    }}>
                        <Typography variant="body1">
                            {userScore !== null ? userScore.toFixed(2) : 'N/A'}
                        </Typography>
                    </Avatar>

                </Box>

                <CardContent style={{paddingTop: 50}}>
                    <Typography variant="h6" style={{marginBottom: '15px', fontWeight: 500, color: '#333'}}>
                        {quiz.title}
                    </Typography>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        style={{
                            background: '#e0e0e0',
                            borderRadius: '50%',
                            width: '80px',
                            height: '80px',
                            margin: 'auto'
                        }}
                    >
                        <Avatar
                            style={{width: '60px', height: '60px', fontSize: '2rem', background: '#fff'}}
                        >
                            {icon}
                        </Avatar>
                    </Box>
                </CardContent>
            </Box>{isAdmin && (
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
                <Button variant="contained" color="secondary" onClick={handleDeleteClick}>
                    Șterge Testul
                </Button>
                <Button variant="contained" color="secondary" onClick={handleEditQuizClick}>
                    Editează testul
                </Button>
                <QuizEditModal quiz={quiz} open={openModal} handleClose={handleClose} />
            </div>

        )}
        </Card>
    );
};

export default QuizComponent;
