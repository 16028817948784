import React from 'react';
import {
    Container,
    Box,
    Typography,
    TextField,
    Button,
    Snackbar,
    Alert
} from '@mui/material';
import { Link } from 'react-router-dom'; // pasul 1

const LoginForm = ({
                       onSubmit,
                       username,
                       setUsername,
                       password,
                       setPassword,
                       errorMessage,
                       successMessage,
                       openSnackbar,
                       handleCloseSnackbar
                   }) => {
    return (
        <Container>
            <Box mt={4} py={6}>
                <Typography variant="h3" gutterBottom align="center">
                    Logare
                </Typography>

                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                >
                    <form
                        noValidate
                        autoComplete="off"
                        onSubmit={onSubmit}
                        style={{width: '100%', maxWidth: '400px'}}
                    >
                        <Box mb={2}>
                            <TextField
                                fullWidth
                                label="Email"
                                variant="outlined"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </Box>
                        <Box mb={2}>
                            <TextField
                                fullWidth
                                label="Parolă"
                                variant="outlined"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Box>
                        <Box mb={2} textAlign="left"> {/* Stilizare adăugată pentru a alinia linkul la dreapta */}
                            <Link to="/recuperare-parolă" style={{textDecoration: 'none'}}>Ai uitat parola?</Link>
                        </Box>
                        <Box mt={2}>
                            <Button variant="contained" color="primary" type="submit">
                                Autentificare
                            </Button>
                        </Box>

                        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                        {successMessage && <Typography color="primary">{successMessage}</Typography>}

                        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                            <Alert onClose={handleCloseSnackbar} severity={errorMessage ? "error" : "success"}>
                                {errorMessage || successMessage}
                            </Alert>
                        </Snackbar>
                    </form>
                </Box>
            </Box>
        </Container>
    );
};

export default LoginForm;
