import {useCallback} from "react";
import {getFirestore, collection, addDoc} from "firebase/firestore";

const useSaveQuiz = () => {
    const saveQuizToFirebase = useCallback(async (quizTitle, questions, optionalText) => {
        try {
            const db = getFirestore();
            const quizCollection = collection(db, 'quizzes');
            const docRef = await addDoc(quizCollection, {
                title: quizTitle,
                questions: questions,
                optionalText: optionalText // Save the optional text
            });
            return { success: true, quizId: docRef.id };
        } catch (error) {
            console.error("Error saving quiz:", error);
            return { success: false, error: error.message };
        }
    }, []);

    return { saveQuizToFirebase };
};

export default useSaveQuiz;