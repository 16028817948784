// hooks/useGetUserNotifications.js
import { useState, useEffect } from 'react';
import {query, where, collection, getDocs, getFirestore} from 'firebase/firestore';

const useGetUserNotifications = (userEmail) => {
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const db = getFirestore();
                const q = query(collection(db, 'notifications'), where('userEmail', '==', userEmail));
                const querySnapshot = await getDocs(q);
                const userNotifications = [];
                querySnapshot.forEach((documentSnapshot) => {
                    userNotifications.push(documentSnapshot.data());
                });
                setNotifications(userNotifications);
            } catch (e) {
                console.error("Eroare la obținerea notificărilor: ", e);
            }
        };

        fetchNotifications();
    }, [userEmail]);

    return notifications;
};

export default useGetUserNotifications;
