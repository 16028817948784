import React, {useEffect, useState} from 'react';
import {Box, Typography, Grid, TextField} from '@mui/material';
import {useAuthCheck} from '../../hooks/auth/UseAuthCheck';
import QuizComponent from "../../components/quiz/user/QuizComponent";
import useGetQuizzes from "../../hooks/quiz/UseGetQuizez";
import {useNavigate} from "react-router-dom";

const ViewQuizzesPage = () => {
    const {quizzes, loadQuizzes} = useGetQuizzes();
    const [loaded, setLoaded] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');  // Adăugat această linie
    const history = useNavigate();
    const {user, isAdmin, isLoading} = useAuthCheck();


    useEffect(() => {
        if (user && !loaded) {
            loadQuizzes();
            setLoaded(true);
        }
    }, [user, loadQuizzes, loaded]);

    if (isLoading) {
        return <Typography>Loading...</Typography>;
    }

    if (!user) {
        return <Typography>Trebuie să fiți autentificat pentru a vedea quiz-urile.</Typography>;
    }

    const handleQuizClick = (quizId) => {
        if (!isAdmin) {
            history(`/test/${quizId}`);
        }
    };

    const handleQuizDeletion = (quizId) => {
        loadQuizzes();
    };

    const filteredQuizzes = quizzes.filter(quiz =>
        quiz.title.toLowerCase().includes(searchTerm.toLowerCase())
    );  // Filtrăm quiz-urile pe baza termenului de căutare

    return (
        <Box p={4}>
            <Typography variant="h4" gutterBottom>
                Teste disponibile:
            </Typography>
            <TextField  // Adăugat această componentă
                fullWidth
                variant="outlined"
                margin="normal"
                label="Caută test..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
            />
            <Grid container spacing={3}>
                {filteredQuizzes.map((quiz, index) => (  // Utilizăm "filteredQuizzes" în loc de "quizzes"
                    <Grid item key={index} xs={12} sm={6} md={4}>
                        <QuizComponent
                            email={user.email}
                            quiz={quiz}
                            onClick={() => handleQuizClick(quiz.id)}
                            isAdmin={isAdmin}
                            onDelete={handleQuizDeletion}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default ViewQuizzesPage;
