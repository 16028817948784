import React, { useState } from 'react';
import {Box, FormControl, InputLabel, Select, MenuItem, TextField, OutlinedInput} from '@mui/material';
import HomeworkCard from "../../components/homework/HomeworkCard";
import { useAuthCheck } from "../../hooks/auth/UseAuthCheck";
import { useGetHomeworks } from "../../hooks/homeworks/UseGetHomework";

const HomeworksPage = () => {
    const { user, isAdmin } = useAuthCheck();
    const { homeworks, loading, fetchHomeworks, setHomeworks } = useGetHomeworks();
    const [gradeFilter, setGradeFilter] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    const handleGradeFilterChange = (e) => {
        const grade = e.target.value;
        setGradeFilter(grade);
        fetchHomeworks(grade);
    };

    const handleHomeworkDeleted = (homeworkId) => {
        setHomeworks((prevHomeworks) =>
            prevHomeworks.filter(homework => homework.id !== homeworkId)
        );
    };

    // Filtrăm temele în funcție de termenul de căutare
    const filteredHomeworks = homeworks.filter(homework =>
        homework.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Box p={4}>
            <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                label="Caută temă..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
            />
            <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel
                    id="grade-filter-label"
                    shrink={gradeFilter ? true : false}
                    htmlFor="grade-filter"
                >
                    Filtru Clasă
                </InputLabel>
                <Select
                    labelId="grade-filter-label"
                    value={gradeFilter}
                    onChange={handleGradeFilterChange}
                    labelWidth={80}  // Adaugă aceasta pentru a seta o lățime pentru eticheta înălțată (ridicată)
                    inputProps={{
                        name: 'grade',
                        id: 'grade-filter',
                    }}
                >
                    <MenuItem value="">Toate</MenuItem>
                    <MenuItem value="5">Clasa a5-a</MenuItem>
                    <MenuItem value="6">Clasa a6-a</MenuItem>
                    <MenuItem value="7">Clasa a7-a</MenuItem>
                    <MenuItem value="8">Clasa a8-a</MenuItem>
                </Select>
            </FormControl>







            {!loading && filteredHomeworks.map(homework => (
                <HomeworkCard
                    key={homework.id}
                    homework={homework}
                    email={user ? user.email : ''}
                    isAdmin={isAdmin}
                    onHomeworkDeleted={handleHomeworkDeleted}
                />
            ))}
        </Box>
    );
};

export default HomeworksPage;
