// hooks/useCreateNotification.js
import {addDoc, collection, getFirestore} from 'firebase/firestore';

const useCreateNotification = () => {
    const createNotification = async (tema, text, score, userEmail) => {
        try {
            const db = getFirestore();
            const docRef = await addDoc(collection(db, 'notifications'), {
                tema,
                text,
                score,
                userEmail,
                createdAt: new Date()
            });
            return docRef.id;  // ID-ul documentului nou creat
        } catch (e) {
            console.error("Eroare la crearea notificării: ", e);
            return null;
        }
    };

    return createNotification;
};

export default useCreateNotification;
