import { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, query, orderBy, limit } from 'firebase/firestore';

const useLimitedResources = (count = 10) => {
    const [resources, setResources] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const db = getFirestore();
                const materialsQuery = query(collection(db, 'posts'), orderBy("title"), limit(count));
                const snapshot = await getDocs(materialsQuery);

                let fetchedResources = [];
                snapshot.forEach(doc => {
                    fetchedResources.push(doc.data());
                });
                setResources(fetchedResources);
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [count]);

    return { resources, isLoading, error };
}

export default useLimitedResources;
