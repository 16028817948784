import React from 'react';
import {Card, CardContent, Typography, Button, Box, Avatar} from '@mui/material';

const WritedResource = ({ title, description, fileUrl, fileName, classes, isAdmin, onDelete }) => {
    const handleDownload = async () => {
        try {
            const response = await fetch(fileUrl);
            if (!response.ok) {
                throw new Error('Nu s-a putut descărca fișierul');
            }
            const blob = await response.blob();

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName; // Utilizăm numele original al fișierului
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (err) {
            console.error("Eroare la descărcarea fișierului:", err);
            alert("A apărut o eroare la descărcarea fișierului. Vă rugăm să încercați din nou mai târziu.");
        }
    }


    return (
        <Card style={{ position: "relative" }}>
            <CardContent style={{ paddingTop: 50 }}> {/* Adăugat un spațiu rezervat în partea de sus */}
                {/* Render avatare pentru clase */}
                <Box position="absolute" top={10} right={10} display="flex">
                    {classes.map((cls, index) => (
                        <Box ml={index !== 0 ? 1 : 0} key={cls}>
                            <Avatar style={{ width: 25, height: 25 }}>{cls.split(/[\s-]+/).find(item => !isNaN(item))}</Avatar>
                        </Box>
                    ))}
                </Box>
                <Typography variant="h6">{title}</Typography>
                <Typography color="textSecondary">{description}</Typography>
                <Button variant="outlined" onClick={handleDownload}>Descarcă</Button>
                {isAdmin && (
                    <Button style={{margin:20}}variant="contained" color="secondary" onClick={() => onDelete(title)}>
                        Șterge
                    </Button>
                )}
            </CardContent>
        </Card>
    );
}

export default WritedResource;
