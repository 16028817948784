import { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

const usePasswordReset = (user) => {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const handlePasswordReset = () => {
        const auth = getAuth();

        if (user && user.email) {
            sendPasswordResetEmail(auth, user.email)
                .then(() => {
                    setSuccess(true);
                    alert('Un e-mail de resetare a parolei a fost trimis la adresa ta.');
                })
                .catch((err) => {
                    setError(err.message);
                    alert('Eroare la resetarea parolei: ' + err.message);
                });
        } else {
            setError('Niciun utilizator autentificat sau email lipsește.');
        }
    };

    const resetState = () => {
        setError(null);
        setSuccess(false);
    };

    return { handlePasswordReset, error, success, resetState };
};

export default usePasswordReset;
