import { useState } from 'react';
import { getFirestore, collection, getDocs, deleteDoc } from 'firebase/firestore';

export const useDeleteHomework = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const deleteHomework = async (title) => {
        setLoading(true);

        try {
            const db = getFirestore();

            // Referințe pentru fiecare colecție
            const homeworkCollection = collection(db, 'homeworks');
            const homeworksRatingCollection = collection(db, 'homeworksRating');
            const homeworksSolutionCollection = collection(db, 'homeworksSolutions');

            const deleteDocsFromCollection = async (collectionRef) => {
                const snapshot = await getDocs(collectionRef);
                const docsToDelete = snapshot.docs.filter(doc => doc.data().title === title);
                for (let doc of docsToDelete) {
                    await deleteDoc(doc.ref);
                }
            };

            await deleteDocsFromCollection(homeworkCollection);
            await deleteDocsFromCollection(homeworksRatingCollection);
            await deleteDocsFromCollection(homeworksSolutionCollection);

            setLoading(false);
            return { success: true };
        } catch (err) {
            setError(err);
            setLoading(false);
            return { success: false, error: err };
        }
    };

    return { deleteHomework, loading, error };
};
