import React, {useState, useEffect} from 'react';
import {Dialog, DialogContent, DialogTitle, Button} from '@mui/material';
import QuestionComponent from './QuestionComponent';
import useUpdateQuiz from "../../../hooks/quiz/UseUpdateQuiz";

const QuizEditModal = ({quiz, open, handleClose}) => {
    const [localQuiz, setLocalQuiz] = useState(quiz);
    const {updateQuizInFirebase} = useUpdateQuiz();
    useEffect(() => {
        if (quiz) {
            setLocalQuiz(quiz);
        }
    }, [quiz]);

    const handleQuestionChange = (updatedQuestion, index) => {
        const updatedQuestions = [...localQuiz.questions];
        updatedQuestions[index] = updatedQuestion;
        setLocalQuiz({...localQuiz, questions: updatedQuestions});
    };

    const handleSubmit = async () => {
        try {
            const {id: quizId, title: quizTitle, questions, optionalText} = localQuiz;
            const result = await updateQuizInFirebase(quizId, quizTitle, questions, optionalText);
            if (result.success) {
                console.log("Quiz updated successfully");
            } else {
                console.error("Failed to update quiz:", result.error);
            }
        } catch (error) {
            console.error("An unexpected error occurred:", error);
        }
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>Edit Quiz</DialogTitle>
            <DialogContent>
                {localQuiz.questions.map((question, index) => (
                    <QuestionComponent
                        key={index}
                        question={question}
                        onQuestionChange={(updatedQuestion) => handleQuestionChange(updatedQuestion, index)}
                    />
                ))}
                <div style={{display: 'flex', flexWrap: 'wrap', gap: 20}}>
                    <Button variant="contained" color="primary" onClick={handleSubmit} style={{marginTop: '20px'}}>
                        Salvează modificările
                    </Button>

                    <Button variant="contained" color="secondary" onClick={()=>handleClose()} style={{marginTop: '20px'}}>
                        Anulare
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default QuizEditModal;