import { useState, useEffect } from 'react';
import {collection, doc, getDoc, getFirestore} from 'firebase/firestore';

const useGetQuizById = (docId) => {
    const [quizData, setQuizData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchQuizFromFirebase = async () => {
            try {
                const db = getFirestore();
                const quizCollection = collection(db, 'quizzes');
                const quizDoc = doc(quizCollection, docId);
                const docSnapshot = await getDoc(quizDoc);

                if (docSnapshot.exists()) {
                    setQuizData(docSnapshot.data());
                } else {
                    setError(new Error('Quiz not found'));
                }
            } catch (fetchError) {
                console.error("Error fetching quiz:", fetchError);
                setError(fetchError);
            } finally {
                console.log('Setting loading to false');
                setLoading(false);
            }
        };

        fetchQuizFromFirebase();
    }, [docId]);

    return { data: quizData, loading, error };
};

export default useGetQuizById;