import { useState } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const useSignOut = () => {
    const [isLoading, setIsLoading] = useState(false); // Pentru a urmări starea de încărcare a semnării ieșirii
    const [error, setError] = useState(null); // O stare pentru orice eroare care ar putea apărea
    const navigate = useNavigate(); // Obține funcția de redirecționare

    const handleSignOut = async () => {
        setIsLoading(true); // Se începe procesul de semnare a ieșirii
        const auth = getAuth();

        try {
            await signOut(auth);
            navigate('/'); // Redirecționează utilizatorul către pagina de acasă
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false); // Semnarea ieșirii s-a încheiat (fie cu succes, fie cu eroare)
        }
    };

    return {
        handleSignOut,
        isLoading,
        error
    };
};

export default useSignOut;
