// hooks/quiz/UseGetQuizResult.js
import { getFirestore, collection, where, query, getDocs } from "firebase/firestore";
import {useEffect, useState} from "react";

const useGetQuizResult = (userId, quizId) => {
    const [userScore, setUserScore] = useState(null);

    useEffect(() => {
        const fetchUserScore = async () => {
            try {
                const db = getFirestore();
                console.log(userId,quizId)
                const q = query(collection(db, 'quizResults'), where('userId', '==', userId), where('quizId', '==', quizId));
                const snapshot = await getDocs(q);

                if (!snapshot.empty) {
                    snapshot.forEach(doc => {
                        setUserScore(doc.data().score);
                    });
                }
            } catch (error) {
                console.error("Error fetching user score:", error);
            }
        }

        fetchUserScore();
    }, [userId, quizId]);
    return userScore;
}
export default useGetQuizResult;