import { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, doc, getDoc } from 'firebase/firestore';

const useQuizResults = () => {
    const [usersData, setUsersData] = useState([]);
    const [loading, setLoading] = useState(true);
    const db = getFirestore();

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch quiz results
                const quizResultsRef = collection(db, 'quizResults');
                const quizResultsSnapshot = await getDocs(quizResultsRef);
                const quizResults = [];
                const userIds = new Set();
                const quizIds = new Set();
                quizResultsSnapshot.forEach(doc => {
                    const data = doc.data();
                    quizResults.push({ ...data, id: doc.id });
                    userIds.add(data.userId);
                    quizIds.add(data.quizId);
                });

                // Fetch quizzes
                const quizzes = {};
                for (const quizId of quizIds) {
                    const quizRef = doc(db, 'quizzes', quizId);
                    const quizSnap = await getDoc(quizRef);
                    if (quizSnap.exists()) {
                        quizzes[quizId] = quizSnap.data().title;
                    }
                }

                // Prepare users data
                const usersData = Array.from(userIds).map(userId => {
                    const userQuizResults = quizResults.filter(result => result.userId === userId);
                    return {
                        userId,
                        quizResults: userQuizResults.map(result => ({
                            ...result,
                            quizTitle: quizzes[result.quizId] || 'Unknown Quiz'
                        }))
                    };
                });

                setUsersData(usersData);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [db]);

    return { usersData, loading ,setUsersData};
};

export default useQuizResults;
