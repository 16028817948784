import React, {useEffect, useState} from 'react';
import {Box, Button, TextField, Checkbox, FormControlLabel, CardContent, Typography, Card, Avatar} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const QuestionComponent = ({question, onQuestionChange}) => {
    const [localQuestion, setLocalQuestion] = useState(question);
    const [hasError, setHasError] = useState(false);
    const removeOption = (index) => {
        if (localQuestion.options.length > 2) {
            setLocalQuestion(prevQuestion => {
                let updatedOptions = [...prevQuestion.options];
                updatedOptions.splice(index, 1);
                let updatedCorrectAnswers = prevQuestion.correctAnswers.filter(answerIndex => answerIndex !== index).map(answerIndex => answerIndex > index ? answerIndex - 1 : answerIndex);
                return { ...prevQuestion, options: updatedOptions, correctAnswers: updatedCorrectAnswers };
            });
        }
    };

    useEffect(() => {
        setHasError(!validateQuestion(localQuestion));
    }, [localQuestion]);
    const handleInputChange = (e, fieldIndex = null) => {
        const {name, value} = e.target;

        setLocalQuestion(prevQuestion => {
            let updatedQuestion = {...prevQuestion};

            if (fieldIndex !== null) {
                let updatedOptions = [...prevQuestion.options];
                updatedOptions[fieldIndex] = value;
                updatedQuestion.options = updatedOptions;
            } else {
                updatedQuestion[name] = value;
            }

            onQuestionChange(updatedQuestion);
            return updatedQuestion;
        });
    };

    const handleCheckboxChange = (event, index) => {
        setLocalQuestion(prevQuestion => {
            let updatedCorrectAnswers = [...prevQuestion.correctAnswers];
            if (event.target.checked) {
                updatedCorrectAnswers.push(index);
            } else {
                updatedCorrectAnswers = updatedCorrectAnswers.filter(i => i !== index);
            }

            let updatedQuestion = {...prevQuestion, correctAnswers: updatedCorrectAnswers};
            onQuestionChange(updatedQuestion);
            return updatedQuestion;
        });
    };


    const addOption = () => {
        if (localQuestion.options.length < 5) {
            setLocalQuestion({...localQuestion, options: [...localQuestion.options, '']});
        }
    };


    const validateQuestion = (updatedQuestion) => {
        return updatedQuestion.correctAnswers.length > 0 && updatedQuestion.questionText !== '' && !updatedQuestion.options.some(opt => opt === '');
    };
    return (
        <Card variant="outlined" style={{ marginBottom: '20px', border: '0' }}>
            <CardContent>
                <TextField
                    name="questionText"
                    label="Textul Întrebării"
                    fullWidth
                    value={localQuestion.questionText}
                    onChange={handleInputChange}
                />
                {localQuestion.options.map((option, index) => (
                    <Box key={index} mt={2} display="flex" flexDirection="row" flexWrap="wrap" alignItems="center">
                        <TextField
                            fullWidth
                            style={{ flex: 1, minWidth:250 }}
                            value={option}
                            label={`Opțiunea ${index + 1}`}
                            onChange={(e) => handleInputChange(e, index)}
                        />
                        <Box display="flex" alignItems="center" ml={1}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={localQuestion.correctAnswers.includes(index)}
                                        onChange={(e) => handleCheckboxChange(e, index)}
                                        name={`checkbox-${index}`}
                                    />
                                }
                                label="Corect?"
                            />
                            <Button onClick={() => removeOption(index)} disabled={localQuestion.options.length <= 2}>
                                <RemoveCircleOutlineIcon />
                            </Button>
                        </Box>
                    </Box>

                ))}
                {hasError && <Typography color="error">Selectați cel puțin o opțiune corectă!</Typography>}
                <Button onClick={addOption} disabled={localQuestion.options.length >= 5}>
                    <AddCircleOutlineIcon />
                    Adăugați o opțiune
                </Button>
            </CardContent>
        </Card>
    );
};

export default QuestionComponent;
