import { Box, Container, Avatar, Typography } from '@mui/material';

const IntroductionSection = ({ isMobile }) => (
    <Box bgcolor="primary.main" color="white" py={isMobile ? 2 : 3}>
        <Container>
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection={isMobile ? 'column' : 'row'} mb={4}>
                <Avatar src="Profil2.jpg" alt="MarinaGabriela" sx={{ width: 60, height: 60, mr: isMobile ? 0 : 2, mb: isMobile ? 2 : 0 }} />
                <Typography
                    variant="h2"
                    align="center"
                    style={{
                        fontSize: '1.5rem', // Setează dimensiunea dorită a fontului aici
                        wordBreak: 'break-word',
                        lineHeight: 1.2,
                    }}
                >
                    Bun venit pe <b>gabrielamarina.com</b>!
                </Typography>
            </Box>
            <Typography   variant="h2"
                          align="center"
                          style={{
                              fontSize: '1.5rem', // Setează dimensiunea dorită a fontului aici
                              wordBreak: 'break-word',
                              lineHeight: 1.2,
                          }}>
                Explorează cele mai noi materiale de învățare și resurse pentru Limba și Literatura Română.
            </Typography>
        </Container>
    </Box>
);

export default IntroductionSection;
