import { useState } from 'react';
import { getFirestore, collection, getDocs, deleteDoc } from 'firebase/firestore';

export const useDeleteResource = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const deletePost = async (deleteTitle) => {
        setLoading(true);

        const db = getFirestore();
        const postsCollection = collection(db, 'posts');
        const snapshot = await getDocs(postsCollection);

        let postToDelete;

        snapshot.docs.forEach(doc => {
            if (doc.data().title === deleteTitle) {
                postToDelete = doc;
            }
        });

        if (postToDelete) {
            await deleteDoc(postToDelete.ref);
            setLoading(false);
            return { success: true };
        } else {
            setError(new Error('Postarea nu a fost găsită.'));
            setLoading(false);
            return { success: false, error: 'Postarea nu a fost găsită.' };
        }
    };

    return { deletePost, loading, error };
};
