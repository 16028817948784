import { useState } from 'react';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import useCreateNotification from "../notifications/UseCreateNotification";

export const useUploadHomeworkRating = (homeworkId, homeworkTitle) => {
    const [rating, setRating] = useState("");
    const [comment, setComment] = useState("");
    const[email,setEmail] = useState("")
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const createNotification = useCreateNotification(); // Pasul 2

    const saveRating = async (userEmail) => {
        const db = getFirestore();
        const homeworksDocRef = doc(db, 'homeworksRating', homeworkId);

        try {
            await setDoc(homeworksDocRef, {
                title: homeworkTitle,
                rating: rating,
                comment: comment,
                userEmail: userEmail
            }, { merge: true });

            setSuccessMessage("Ratingul și comentariul au fost salvate cu succes!");
            setError(null); // Reset the error if there was one previously

            // Pasul 3: Apelează createNotification după un succes
            const tema = homeworkTitle; // sau orice alt titlu pe care dorești să-l folosești pentru notificare
            const text = comment;
            const score = rating; // presupunând că ratingul este un număr
            await createNotification(tema, text, score, userEmail);

        } catch (err) {
            setError(err.message || "A apărut o eroare la salvarea ratingului.");
            setSuccessMessage(null); // Reset the success message if there was one previously
        }
    };

    return {
        rating,
        comment,
        email,
        setRating,
        setComment,
        setEmail,
        saveRating,
        error,
        successMessage,
        setError,
        setSuccessMessage
    };
};
