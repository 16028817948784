import React, { useEffect } from 'react';
import {Container, Box, Typography, Avatar, Button, Badge, LinearProgress} from '@mui/material';
import { ProgressBar, Badge as BootstrapBadge } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useAuthCheck } from "../../hooks/auth/UseAuthCheck";
import usePasswordReset from "../../hooks/auth/UsePasswordReset";
import useGetTotalScore from "../../hooks/quiz/UseGetTotalScore";


const ProfilePage = () => {
    const { user, isAdmin, isLoading } = useAuthCheck();
    const navigate = useNavigate();
    const { handlePasswordReset, error, success, resetState } = usePasswordReset(user);
    const { getTotalScoreForUser } = useGetTotalScore();
    const [totalScore, setTotalScore] = React.useState(null);
    const [level, setLevel] = React.useState(1);
    const [progress, setProgress] = React.useState(0); // Adăugat pentru a păstra progresul
    const [pointsNeeded, setPointsNeeded] = React.useState(0);

    useEffect(() => {
        if (user && user.email) {
            getTotalScoreForUser(user.email).then(result => {
                if (result.success) {
                    setTotalScore(result.totalScore);
                    const newLevel = Math.floor(Math.log2(result.totalScore / 20 + 1)) + 1;
                    setLevel(newLevel);
                    // Calculam procentajul progresului catre urmatorul nivel
                    const nextLevelScore = (2 ** (newLevel - 1)) * 20;
                    const progressPercentage = ((result.totalScore / nextLevelScore) * 100).toFixed(2);

                    setProgress(progressPercentage);

                    // Calculam cate puncte mai sunt necesare pentru a ajunge la nivelul urmator
                    const pointsNeeded = (nextLevelScore - result.totalScore).toFixed(2);

                    setPointsNeeded(pointsNeeded);
                }
            });
        }
    }, [user, getTotalScoreForUser]);
    useEffect(() => {
        if (error) {
            alert(error);
            resetState();  // resetează starea după afișarea erorii
        }
    }, [error, resetState]);
    if (isLoading) {
        return <div>Încărcare...</div>;
    }

    if (!user) {
        navigate('/');
    }

    return (
        <Container>
            <Box mt={4} py={6}>
                <Typography variant="h3" gutterBottom align="center">
                    Profil
                </Typography>

                <Box display="flex" flexDirection="column" alignItems="center">
                    <Badge color="primary" badgeContent={isAdmin ? "Admin" : null}>
                        <Avatar sx={{ width: 60, height: 60 }}>
                            {user && user.email ? user.email[0].toUpperCase() : ""}
                        </Avatar>
                    </Badge>

                    <Typography variant="h6" gutterBottom>
                        {user ? user.email : ''}
                        {isAdmin && <span style={{ color: 'red', marginLeft: '10px' }}>(Administrator)</span>}
                    </Typography>

                    {success ? (
                        <Typography variant="body1" gutterBottom>
                            Un email a fost trimis către {user.email} pentru resetarea parolei!
                        </Typography>
                    ) : (
                        <Button variant="contained" color="primary" onClick={handlePasswordReset}>
                            Schimbă Parola
                        </Button>
                    )}

                    {totalScore !== null && (
                        <Box mt={4} p={3} style={{
                            borderRadius: '12px',
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                            background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
                            width: '80%'
                        }}>
                            <Typography variant="h5" align="center" gutterBottom>
                                Nivel <BootstrapBadge pill variant="info">{level}</BootstrapBadge>
                            </Typography>
                            <Typography variant="subtitle1" align="center" gutterBottom>
                                Scor total: <BootstrapBadge pill variant="primary">{totalScore.toFixed(2)}</BootstrapBadge>
                            </Typography>

                            {/* Bara de progres folosind Material-UI */}
                            <LinearProgress
                                variant="determinate"
                                value={progress}
                                style={{ height: '24px', borderRadius: '12px', marginBottom: '12px' }}
                            />

                            {/* Textul care afișează câte puncte mai sunt necesare pentru a avansa la nivelul următor */}
                            <Typography variant="body2" align="center" gutterBottom>
                                Ai nevoie de încă {pointsNeeded} puncte pentru nivelul {level + 1}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        </Container>
    );
};

export default ProfilePage;