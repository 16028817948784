import React from 'react';
import LoginForm from "../../components/login/LoginForm";
import useLogin from "../../hooks/auth/UseLogin";

const LoginPage = () => {
    const loginProps = useLogin();
    return <LoginForm {...loginProps} />;
};

export default LoginPage;
