import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import UseGetUserName from "../../../hooks/auth/UseGetUserName";
import { FaTrashAlt } from 'react-icons/fa'; // Importarea unui icon de ștergere

const UserCard = ({ user, onDelete }) => {
    const [open, setOpen] = useState(false);
    const { userName, loading, error } = UseGetUserName(user.userId);

    const toggleOpen = () => setOpen(!open);

    const formatScore = (score) => parseFloat(score).toFixed(2);

    return (
        <Card style={{ marginBottom: '1rem' }}>
            <Card.Header onClick={toggleOpen} style={{ cursor: 'pointer' }}>
                <strong>{loading ? 'Loading...' : error ? user.userId : userName}</strong>
                <span style={{ float: 'right' }}>{open ? '▲' : '▼'}</span>
            </Card.Header>
            {open && (
                <Card.Body>
                    <Card.Title>Quiz Results</Card.Title>
                    <ul>
                        {user.quizResults.map((result, index) => (
                            <li key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                {result.quizTitle}: <strong>{formatScore(result.score)}</strong> (Nota)
                                <Button
                                    variant="outline-danger"
                                    size="sm"
                                    onClick={() => onDelete(result.id)}
                                    style={{ marginLeft: '10px' }}
                                >
                                    <FaTrashAlt /> Șterge
                                </Button>
                            </li>
                        ))}
                    </ul>
                </Card.Body>
            )}
        </Card>
    );
};

export default UserCard;
