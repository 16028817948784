import React, {useState, useRef, useEffect} from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress,
    IconButton,
    Avatar, FormLabel, DialogContent, Dialog, DialogTitle
} from '@mui/material';

const AddHomeworkForm = ({
                             title,
                             setTitle,
                             requirement,
                             setRequirement,
                             image,
                             setImage,
                             onSubmit,
                             loading,
                             grade,
                             setGrade
                         }) => {
    const [blink, setBlink] = useState(false);
    const [imageUploaded, setImageUploaded] = useState(false);
    const [invalidFile, setInvalidFile] = useState(false);
    const imageInputRef = useRef(null);
    const [openImageDialog, setOpenImageDialog] = useState(false);

    const handleOpenImageDialog = () => {
        setOpenImageDialog(true);
    };

    const handleCloseImageDialog = () => {
        setOpenImageDialog(false);
        if (image) {
            URL.revokeObjectURL(image);
        }
    };
    useEffect(() => {
        if (loading) {
            setBlink(true);
            setTimeout(() => setBlink(false), 1000);
        }
    }, [loading]);
    useEffect(() => {
        return () => {
            if (image) {
                URL.revokeObjectURL(image);
            }
        };
    }, [image]);


    const handleImageChange = (e) => {
        const selectedFile = e.target.files[0];
        const validTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];

        if (selectedFile && validTypes.includes(selectedFile.type)) {
            setImage(selectedFile);
            setImageUploaded(true);
            setInvalidFile(false);
        } else {
            setInvalidFile(true);
        }
    };

    return (
        <Box my={4} style={{opacity: blink ? 0.5 : 1, transition: 'opacity 0.5s'}}>
            <Typography variant="h4" gutterBottom>
                Adăugați temă
            </Typography>

            {/* Grup pentru Titlu și Cerință */}
            <Box mb={3}>
                <TextField fullWidth margin="normal" label="Titlu" variant="outlined" value={title} onChange={(e) => setTitle(e.target.value)} />
                <TextField fullWidth margin="normal" label="Cerință" variant="outlined" value={requirement} onChange={(e) => setRequirement(e.target.value)} />
            </Box>

            {/* Grup pentru Selectarea Clasei */}
            <Box mb={3}>
                <FormLabel component="legend">Pentru ce clasă este tema?</FormLabel>
                <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel id="grade-label">Clasă</InputLabel>
                    <Select labelId="grade-label" value={grade} onChange={(e) => setGrade(e.target.value)}>
                        <MenuItem value="5">Clasa a5-a</MenuItem>
                        <MenuItem value="6">Clasa a6-a</MenuItem>
                        <MenuItem value="7">Clasa a7-a</MenuItem>
                        <MenuItem value="8">Clasa a8-a</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            {/* Grup pentru Încărcarea Imaginii */}
            <Box mb={3}>
                <FormLabel component="legend">Fotografiază sau încarcă o fotografie cu tema!</FormLabel>
                <Box display="flex" alignItems="center">
                    <input type="file" ref={imageInputRef} style={{ display: 'none' }} onChange={handleImageChange} id="upload-homework-image" accept="image/*" />
                    <label htmlFor="upload-homework-image">
                        <Button variant="contained" color="secondary" component="span">
                            {imageUploaded ? "Reîncarcă Imagine" : "Încarcă Imagine"}
                        </Button>
                    </label>
                    {imageUploaded && image instanceof File && (
                        <Avatar
                            src={URL.createObjectURL(image)}
                            style={{marginLeft: '10px', cursor: 'pointer', width: 60, height: 60}}
                            alt="Uploaded Homework Image"
                            onClick={handleOpenImageDialog}
                        />
                    )}
                    {invalidFile && <Typography variant="body2" color="error" style={{marginLeft: 16}}>Poți încărca doar imagini!</Typography>}
                </Box>
            </Box>

            {/* Grup pentru Buton */}
            <Box mt={2}>
                <FormLabel component="legend">După ce ai completat toate câmpurile apasă butonul pentru a adăuga tema</FormLabel>
                <Button variant="contained" color="primary" type="submit" onClick={onSubmit} disabled={loading}>
                    {loading ? (
                        <>
                            Încărcare...
                        </>
                    ) : (
                        'Adaugă tema'
                    )}
                </Button>
            </Box>

            {/* Dialog pentru vizualizare imagine */}
            <Dialog open={openImageDialog} onClose={handleCloseImageDialog}>
                <DialogTitle>Tema încărcată</DialogTitle>
                <DialogContent>
                    {imageUploaded && image instanceof File && (
                    <img src={URL.createObjectURL(image)} alt="Uploaded Homework"
                         style={{width: '100%', height: 'auto'}}/>)
                    }
                </DialogContent>
            </Dialog>
        </Box>
    );

};

export default AddHomeworkForm;
