// hooks/homeworks/UseCheckFeedbackStatus.js
import { useState, useEffect } from 'react';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

const useCheckFeedbackStatus = (title, userEmail) => {
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkFeedback = async () => {
            const db = getFirestore();
            const feedbackCollection = collection(db, 'homeworksRating');// Presupun că există o colecție numită "feedbacks"

            const feedbackQuery = query(feedbackCollection,
                where('title', '==', title),
                where('userEmail', '==', userEmail)
            );

            const feedbackSnapshot = await getDocs(feedbackQuery);

            if (feedbackSnapshot.size > 0) {
                setFeedbackSubmitted(true);
            }
            setIsLoading(false);
        };

        checkFeedback();
    }, [title, userEmail]);

    return { feedbackSubmitted, isLoading };
};

export default useCheckFeedbackStatus;
