import { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';

export const useGetHomeworks = () => {
    const [homeworks, setHomeworks] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchHomeworks = async (gradeFilter) => {
        setLoading(true);

        const db = getFirestore();
        const homeworkCollection = collection(db, 'homeworks');

        let homeworkQuery = homeworkCollection;
        if (gradeFilter) {
            homeworkQuery = query(homeworkCollection, where('class', '==', `a${gradeFilter}`));
        }

        try {
            const homeworkDocs = await getDocs(homeworkQuery);
            const fetchedHomeworks = homeworkDocs.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            setHomeworks(fetchedHomeworks);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching homeworks:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchHomeworks();
    }, []);

    return { homeworks, loading, fetchHomeworks,setHomeworks };
};
