

import { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

const useFetchSubmittedHomeworks = () => {
    const [submittedHomeworks, setSubmittedHomeworks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchSubmittedHomeworks = async () => {
            const db = getFirestore();
            const homeworksCollection = collection(db, 'homeworksSolutions');

            const homeworksSnapshot = await getDocs(homeworksCollection);
            setSubmittedHomeworks(homeworksSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
            setIsLoading(false);
        };

        fetchSubmittedHomeworks();
    }, []);

    return { submittedHomeworks, isLoading };
};

export default useFetchSubmittedHomeworks;
