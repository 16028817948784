import React, {useEffect, useState} from 'react';
import { Container, Typography, Box, Select, MenuItem } from '@mui/material';
import ResourcesList from "../../components/resources/ResourceList";
import useFilteredResources from "../../hooks/resources/UseFilteredResources";
import {useAuthCheck} from "../../hooks/auth/UseAuthCheck";
import {useDeleteResource} from "../../hooks/resources/UseDeleteResource";

const MaterialsPage = () => {
    const [selectedClass, setSelectedClass] = useState('all');
    const [selectedType, setSelectedType] = useState('all');
    const [refreshToggle, setRefreshToggle] = useState(false); // Adăugat această linie
    const materials = useFilteredResources(selectedClass, selectedType, refreshToggle); // Modificat această linie
    const { isAdmin } = useAuthCheck();
    const { deletePost } = useDeleteResource();
    const [resources, setResources] = useState([]);

    useEffect(() => {
        setResources(materials);
    }, [materials]);

    const handleResourceDelete = async (title) => {
        const result = await deletePost(title);
        if (result.success) {
            const updatedResources = resources.filter(resource => resource.title !== title);
            setResources(updatedResources);
        }
        return result;
    };
    return (
        <Container>
            <Box mt={4} py={6}>
                <Typography variant="h3" gutterBottom align="center">
                    Materiale
                </Typography>

                <Box display="flex" justifyContent="center" mb={4} gap={2}>
                    <Select
                        value={selectedClass}
                        onChange={e => setSelectedClass(e.target.value)}
                    >
                        <MenuItem value="all">Toate materialele</MenuItem>
                        <MenuItem value="a 5-a">Clasa a 5-a</MenuItem>
                        <MenuItem value="a 6-a">Clasa a 6-a</MenuItem>
                        <MenuItem value="a 7-a">Clasa a 7-a</MenuItem>
                        <MenuItem value="a 8-a">Clasa a 8-a</MenuItem>
                    </Select>

                    <Select
                        value={selectedType}
                        onChange={e => setSelectedType(e.target.value)}
                    >
                        <MenuItem value="all">Toate tipurile</MenuItem>
                        <MenuItem value="video">Videoclipuri</MenuItem>
                        <MenuItem value="written">Fișiere</MenuItem>
                    </Select>
                </Box>

                {/* Display the resources using ResourcesList component */}
                <ResourcesList resources={resources} isAdmin={isAdmin} onDelete={handleResourceDelete} refreshList={() => { setSelectedClass('all'); setSelectedType('all'); }} />
            </Box>
        </Container>
    );
};

export default MaterialsPage;
