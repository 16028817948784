import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import useGetQuizById from "../../hooks/quiz/UseGetQuizById";
import QuestionComponentForQuiz from "../../components/quiz/user/QuestionComponentForQuiz";
import ResultComponent from "../../components/quiz/user/ResultComponent";
import { useAuthCheck } from "../../hooks/auth/UseAuthCheck";

const QuizPage = () => {
    const { quizId } = useParams();
    const { data, loading, error } = useGetQuizById(quizId);
    const { user } = useAuthCheck();
    const [randomizedQuestions, setRandomizedQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [isQuizFinished, setIsQuizFinished] = useState(false);
    const [score, setScore] = useState(0);

    useEffect(() => {
        if (data && data.questions) {
            const shuffledQuestions = [...data.questions].sort(() => 0.5 - Math.random());
            setRandomizedQuestions(shuffledQuestions);
        }
    }, [data]);

    function arraysEqual(a, b) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        a.sort();
        b.sort();

        for (let i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    }

    const handleAnswerSelection = (answers) => {
        const numericAnswers = answers.map(answer => parseInt(answer, 10));
        const correctAnswers = randomizedQuestions[currentQuestionIndex].correctAnswers;

        let questionScore = 0;

        if (arraysEqual(numericAnswers, correctAnswers)) {
            questionScore = 1;
        } else if (numericAnswers.every(answer => correctAnswers.includes(answer))) {
            questionScore = 1 / correctAnswers.length;
        }

        setScore(prevScore => prevScore + questionScore);
    };

    const handleNextQuestion = () => {
        if (currentQuestionIndex < randomizedQuestions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            setIsQuizFinished(true);
        }
    };

    if (loading) {
        return <p>Loading quiz...</p>;
    }

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    if (!randomizedQuestions.length) {
        return <p>No quiz data found.</p>;
    }

    if (isQuizFinished) {
        return <ResultComponent score={score} totalQuestions={randomizedQuestions.length} userId={user.email} quizId={quizId} />;
    }

    return (
        <div>
            <QuestionComponentForQuiz
                question={randomizedQuestions[currentQuestionIndex]}
                optionalText={data.optionalText}
                onAnswerSelection={handleAnswerSelection}
                onNext={handleNextQuestion}
                currentQuestionIndex={currentQuestionIndex + 1}
                totalQuestions={randomizedQuestions.length}
            />
        </div>
    );
};

export default QuizPage;
