// hooks/useDeleteUserNotifications.js
import {query, where, collection, getDocs, deleteDoc, doc, getFirestore} from 'firebase/firestore';


const useDeleteUserNotifications = () => {
    const deleteUserNotifications = async (userEmail) => {
        try {
            const db = getFirestore();
            const q = query(collection(db, 'notifications'), where('userEmail', '==', userEmail));
            const querySnapshot = await getDocs(q);
            const batchPromises = [];

            querySnapshot.forEach((documentSnapshot) => {
                const deletePromise = deleteDoc(doc(db, 'notifications', documentSnapshot.id));
                batchPromises.push(deletePromise);
            });

            await Promise.all(batchPromises); // Așteaptă finalizarea tuturor operațiunilor de ștergere
        } catch (e) {
            console.error("Eroare la ștergerea notificărilor pentru utilizator: ", e);
        }
    };

    return deleteUserNotifications;
};

export default useDeleteUserNotifications;
