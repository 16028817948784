import { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';

const useFilteredResources = (selectedClass, selectedType) => {
    const [materials, setMaterials] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const db = getFirestore();
            const materialsCollection = collection(db, 'posts');

            let materialsQuery;

            if (selectedClass !== 'all' && selectedType !== 'all') {
                materialsQuery = query(materialsCollection,
                    where("classes", "array-contains", selectedClass),
                    where("type", "==", selectedType)
                );
            } else if (selectedClass !== 'all') {
                materialsQuery = query(materialsCollection, where("classes", "array-contains", selectedClass));
            } else if (selectedType !== 'all') {
                materialsQuery = query(materialsCollection, where("type", "==", selectedType));
            } else {
                materialsQuery = materialsCollection;
            }

            const snapshot = await getDocs(materialsQuery);
            let fetchedMaterials = [];
            snapshot.forEach(doc => {
                fetchedMaterials.push(doc.data());
            });

            setMaterials(fetchedMaterials);
        };

        fetchData();
    }, [selectedClass, selectedType]);

    return materials;
}

export default useFilteredResources;
