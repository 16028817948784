// src/hooks/quiz/UseGetQuizzes.js

import { useState, useCallback } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

const useGetQuizzes = () => {
    const [quizzes, setQuizzes] = useState([]);

    const loadQuizzes = useCallback(async () => {
        const db = getFirestore();
        const querySnapshot = await getDocs(collection(db, 'quizzes'));

        const loadedQuizzes = querySnapshot.docs.map(doc => {
            return { id: doc.id, ...doc.data() };
        });

        setQuizzes(loadedQuizzes);
    }, []);

    return { quizzes, loadQuizzes };
};

export default useGetQuizzes;
