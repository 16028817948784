import { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

const usePasswordReset = () => {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const handlePasswordReset = (email) => {
        const auth = getAuth();

        if (email) {
            sendPasswordResetEmail(auth, email)
                .then(() => {
                    setSuccess(true);
                    alert('Un e-mail de resetare a parolei a fost trimis la adresa specificată.');
                })
                .catch((err) => {
                    setError(err.message);
                    alert('Eroare la resetarea parolei: ' + err.message);
                });
        } else {
            setError('Adresa de e-mail lipsește.');
        }
    };

    const resetState = () => {
        setError(null);
        setSuccess(false);
    };

    return { handlePasswordReset, error, success, resetState };
};

export default usePasswordReset;
