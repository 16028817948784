import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

export const loginUser = async (username, password) => {
    try {
        const auth = getAuth();
        const userCredential = await signInWithEmailAndPassword(auth, username, password);
        return userCredential.user; // returnează user-ul
    } catch (error) {
        throw error; // propagă eroarea către caller
    }
};
