import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthCheck } from "../../hooks/auth/UseAuthCheck";

const ProtectedRoute = ({ children, redirectPath, checkAdmin = false, noAdminRedirectPath }) => {
    const { user, isAdmin, isLoading } = useAuthCheck();

    if (isLoading) {
        // Așteaptă finalizarea verificării
        return <div>Loading...</div>;
    }

    if (!user) {
        return <Navigate to={redirectPath} replace />;
    }

    if (checkAdmin && !isAdmin) {
        return <Navigate to={noAdminRedirectPath?noAdminRedirectPath:redirectPath} replace />;
    }

    return children;
};

export default ProtectedRoute;