import { useState } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getFirestore, collection, addDoc } from 'firebase/firestore';

export const useAddResource = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const addPost = async (title, description, file, fileType, videoLink, selectedClasses) => {
        setLoading(true);

        try {
            const selectedClassArray = Object.keys(selectedClasses).filter(cls => selectedClasses[cls]);
            let downloadURL = '';
            if (fileType === 'scris') {
                const storage = getStorage();
                const fileRef = ref(storage, `uploads/${file.name}`);
                const snapshot = await uploadBytes(fileRef, file);
                downloadURL = await getDownloadURL(snapshot.ref);
            } else {
                downloadURL = videoLink;
            }

            const db = getFirestore();
            const postsCollection = collection(db, 'posts');
            await addDoc(postsCollection, {
                title,
                description,
                fileUrl: downloadURL,
                fileName: fileType === 'scris' ? file.name : '', // Stocăm numele fișierului
                type: fileType === 'scris' ? 'written' : 'video',
                classes: selectedClassArray
            });

            setLoading(false);
            return { success: true };
        } catch (err) {
            setError(err);
            setLoading(false);
            return { success: false, error: err };
        }
    };

    return { addPost, loading, error };
};
