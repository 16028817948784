import {useState} from 'react';
import {getFirestore, collection, addDoc} from 'firebase/firestore';
import {getStorage, ref, uploadBytes, getDownloadURL} from 'firebase/storage';

export const useUploadHomework = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const uploadHomework = async (title, requirement, image, grade) => {
        setLoading(true);
        try {
            // Încărcarea imaginii în Firebase Storage
            const storage = getStorage();
            const storageRef = ref(storage, 'homework-images/' + image.name);
            await uploadBytes(storageRef, image);
            const imageUrl = await getDownloadURL(storageRef);

            // Salvarea metadatelor în Firestore
            const db = getFirestore();
            const homeworkCollection = collection(db, 'homeworks');
            await addDoc(homeworkCollection, {
                title,
                requirement,
                imageUrl,
                class: `a${grade}` // aici adaugi 'class' ca și 'a5', 'a6' etc.
            });

            setLoading(false);
            return {success: true};
        } catch (err) {
            setError(err);
            setLoading(false);
            return {success: false, error: err};
        }
    };

    return {uploadHomework, loading, error};
};
