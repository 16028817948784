import { useState, useEffect } from 'react';
import {getFirestore, doc, getDoc, collection, getDocs} from 'firebase/firestore';

export const useHomeworkFeedback = (title, userEmail) => { // schimbat "email" în "userEmail" aici
    const [feedback, setFeedback] = useState(null);
    const [loadingFeedback, setLoadingFeedback] = useState(true);

    useEffect(() => {
        if (!title || !userEmail) return; // schimbat "email" în "userEmail" aici

        const fetchFeedback = async () => {
            const db = getFirestore();
            const feedbackCollection = collection(db, "homeworksRating");
            const allFeedbackDocs = await getDocs(feedbackCollection);

            for (const feedbackDoc of allFeedbackDocs.docs) {
                const feedbackData = feedbackDoc.data();
                if (feedbackData.title === title && feedbackData.userEmail === userEmail) { // schimbat "email" în "userEmail" aici
                    setFeedback(feedbackData);
                    break;
                }
            }

            setLoadingFeedback(false);
        };

        fetchFeedback();
    }, [title, userEmail]); // schimbat "email" în "userEmail" aici

    return { feedback, loadingFeedback };
};
