import React, {useEffect, useRef, useState} from 'react';
import {
    Box,
    Typography,
    TextField,
    Button,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Checkbox,
    FormGroup,
    FormLabel
} from '@mui/material';

const AddPostForm = ({ postAdded, isVideo, setIsVideo, title, setTitle, description, setDescription, file, setFile, videoLink, setVideoLink, onSubmit, loading, selectedClasses, setSelectedClasses }) => {
    const fileInputRef = useRef(null);
    const [blink, setBlink] = useState(false);
    const [fileUploaded, setFileUploaded] = useState(false);

    useEffect(() => {
        if (postAdded && fileInputRef.current) {
            fileInputRef.current.value = '';
            setBlink(true);
            setTimeout(() => setBlink(false), 1000);
        }
    }, [postAdded]);
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        setFileUploaded(true); // Actualizează starea când fișierul este selectat
    };
    return (
        <Box my={2} style={{ opacity: blink ? 0.5 : 1, transition: 'opacity 0.5s' }}>
            <Typography variant="h5" gutterBottom>Adăugați un material</Typography>
            <TextField fullWidth margin="normal" label="Titlul materialului" variant="outlined" value={title} onChange={(e) => setTitle(e.target.value)} />
            <TextField fullWidth margin="normal" label="Descrierea materialului" variant="outlined" value={description} onChange={(e) => setDescription(e.target.value)} />


            <FormControl component="fieldset" margin="normal" fullWidth>
                <FormLabel component="legend">Ce tip de material doriți să încărcați?</FormLabel>
                <RadioGroup row value={isVideo ? 'video' : 'scris'} onChange={(e) => setIsVideo(e.target.value === 'video')}>
                    <FormControlLabel value="scris" control={<Radio />} label="Doresc să incarc un fișier sau o poză" />
                    <FormControlLabel value="video" control={<Radio />} label="Doresc să atașez un videoclip" />
                </RadioGroup>
            </FormControl>

            {isVideo ? (
                <TextField fullWidth margin="normal" label="Link către videoclip" variant="outlined" value={videoLink} onChange={(e) => setVideoLink(e.target.value)} />
            ) : (
                <Box my={1}>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                        id="contained-button-file"
                    />
                    <label htmlFor="contained-button-file">

                        <Button variant="contained" color="secondary" component="span">
                            {fileUploaded?"Reîncarcă":"Încarcă"}
                        </Button>
                    </label>
                    {fileUploaded && <Typography variant="body2" color="success" style={{ marginLeft: 16 }}>Fișier încărcat cu succes! Poți salva postarea apăsând butonul de adăugare postare.</Typography>}
                </Box>
            )}

            <FormControl component="fieldset" margin="normal" fullWidth>
                <FormLabel component="legend">Pentru ce clase sunt materialele?</FormLabel>
                <FormGroup row>
                    {['a 5-a', 'a 6-a', 'a 7-a', 'a 8-a'].map(clasa => (
                        <FormControlLabel
                            key={clasa}
                            control={
                                <Checkbox
                                    checked={selectedClasses[clasa] || false}
                                    onChange={(e) => setSelectedClasses(prev => ({ ...prev, [clasa]: e.target.checked }))}
                                />
                            }
                            label={clasa}
                        />
                    ))}
                </FormGroup>
            </FormControl>

            <Button variant="contained" color="primary" type="submit" onClick={onSubmit} disabled={loading} fullWidth>
                Adăugă materialul
            </Button>
        </Box>
    );

};

export default AddPostForm;