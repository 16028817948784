import React, {useEffect, useState} from 'react';
import {
    Card,
    CardContent,
    Typography,
    CardMedia,
    Button,
    Box,
    IconButton,
    Tooltip,
    Alert,
    Snackbar, CircularProgress
} from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Modal } from '@mui/material';
import {useUploadSolvedHomework} from "../../hooks/homeworks/UseUploadSolvedHomework";
import {useHomeworkFeedback} from "../../hooks/homeworks/UseHomeworkFeedback";
import {useCheckHomeworkStatus} from "../../hooks/homeworks/UseCheckHomeworkStatus";
import {useDeleteHomework} from "../../hooks/homeworks/UseDeleteHomework";
const HomeworkCard = ({ homework, email, isAdmin, onHomeworkDeleted }) => {
    const [openModal, setOpenModal] = useState(false);
    const { homeworkSolution, loading, error, uploadSolution } = useUploadSolvedHomework(homework.id, homework.title, email,homework.requirement);
    const { feedback, loadingFeedback } = useHomeworkFeedback(homework.title, email);
    const { loading: loadingStatus, homeworkSubmitted } = useCheckHomeworkStatus(homework.title, email);
    const { deleteHomework, loading: deleteLoading, error: deleteError } = useDeleteHomework();
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    useEffect(()=>{console.log("feedback is",feedback)},[feedback])

    const handleDeleteHomework = async () => {
        const { success } = await deleteHomework(homework.title);
        if (success) {
            onHomeworkDeleted(homework.id);
        }
    };
    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleUploadSolution = async (e) => {
        const file = e.target.files[0];
        if (file) {
            await uploadSolution(file);
        }
    };

    const getRatingColor = (rating) => {
        if (rating <= 3) return "#FF7F7F"; // Rosu
        if (rating <= 5) return "#FFBF7F"; // Portocaliu
        if (rating <= 7) return "#FFFF7F"; // Galben
        return "#7FFF7F"; // Verde
    };

    return (
        <Card style={{ marginBottom: '15px', position: 'relative' }}>
            <CardMedia
                component="img"
                height="140"
                width="100"
                image={homework.imageUrl}
                alt={homework.title}
                onClick={handleOpenModal}
                style={{
                    cursor: 'pointer',
                    transition: 'transform 0.3s, opacity 0.3s',
                    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)'
                }}
                sx={{
                    '&:hover': {
                        transform: 'scale(1.08)',
                        opacity: 0.7
                    }
                }}
            />
            <Tooltip title="Mărește imaginea">
                <IconButton
                    onClick={handleOpenModal}
                    style={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: 'white',
                        transition: 'opacity 0.3s'
                    }}
                    sx={{
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.7)'
                        }
                    }}
                >
                    <ZoomInIcon />
                </IconButton>
            </Tooltip>

            <Modal open={openModal} onClose={handleCloseModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                    }}
                >
                    <img src={homework.imageUrl} alt={homework.title} style={{ maxWidth: '100vw', maxHeight: '100vh' }} />
                </Box>
            </Modal>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    {homework.title}
                </Typography>
                <Typography variant="body2">
                    Cerință: {homework.requirement}
                </Typography>
                <Box mt={3}>
                    {loading || loadingFeedback || loadingStatus ? (
                        <Box display="flex" justifyContent="center">
                            <CircularProgress />
                        </Box>
                    ) : feedback ? (
                        <Box>
                            <Box display="flex" alignItems="center" mt={1}>
                                <Box
                                    bgcolor={getRatingColor(feedback.rating)}
                                    px={1} py={0.5}
                                    borderRadius={1}
                                    mr={1}
                                >
                                    <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                                        Notă: {feedback.rating}
                                    </Typography>
                                </Box>
                                <Typography variant="body2" style={{ fontStyle: 'italic' }}>
                                    Comentariu: {feedback.comment}
                                </Typography>
                            </Box>
                        </Box>
                    ) : homeworkSolution ? (
                        <Typography variant="body2">
                            Tema încărcată!
                        </Typography>
                    ) : isAdmin ? (
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleDeleteHomework}
                        >
                            Șterge Tema
                        </Button>
                    ) : (
                        <>
                            <input
                                type="file"
                                accept=".pdf,.doc,.docx,image/*"
                                onChange={handleUploadSolution}
                                style={{ display: 'none' }}
                                id={`upload-${homework.id}`}
                            />
                            <label htmlFor={`upload-${homework.id}`}>
                                <Button variant="outlined" color="primary" component="span">
                                    {homeworkSubmitted ? 'Reîncarcă Tema' : 'Încarcă Tema'}
                                </Button>
                            </label>
                        </>
                    )}
                </Box>
            </CardContent>
            {error && (
                <Snackbar open={true} autoHideDuration={6000} onClose={() => { /* handle close logic */ }}>
                    <Alert onClose={() => { /* handle close logic */ }} severity="error">
                        A apărut o eroare la încărcarea temei!
                    </Alert>
                </Snackbar>
            )}

        </Card>
    );
};

export default HomeworkCard;
