import { useState } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addDoc, collection, getFirestore } from 'firebase/firestore';

export const useUploadSolvedHomework = (homeworkId, themeTitle, userEmail, requirement) => {
    const [homeworkSolution, setHomeworkSolution] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const uploadSolution = async (file) => {
        try {
            setLoading(true);


            const storage = getStorage();
            const fileRef = ref(storage, `theme_solutions/${homeworkId}_${file.name}`);
            await uploadBytes(fileRef, file);
            const downloadURL = await getDownloadURL(fileRef);

            // Salvare în Firestore
            const db = getFirestore();
            const homeworkSolutionsRef = collection(db, 'homeworksSolutions');
            await addDoc(homeworkSolutionsRef, {
                homeworkId: homeworkId,
                requirement: requirement,
                userEmail: userEmail,
                downloadURL: downloadURL,
                title: themeTitle /* aici adaugi titlul temei */
            });
            setHomeworkSolution(downloadURL);
            setLoading(false);
        } catch (uploadError) {
            console.error('Error uploading solution:', uploadError);
            setError(uploadError);
            setLoading(false);
        }
    };

    return { homeworkSolution, loading, error, uploadSolution };
};
