import { useCallback } from "react";
import { getFirestore, collection, addDoc, where, query, getDocs, doc, updateDoc } from "firebase/firestore";

const useSaveQuizResult = () => {
    const saveQuizResultToFirebase = useCallback(async (userId, quizId, newScore) => {
        try {
            const db = getFirestore();
            const quizResultsRef = collection(db, 'quizResults');

            // Încearcă să găsești un rezultat existent pentru utilizatorul și quiz-ul dat
            const q = query(quizResultsRef, where('userId', '==', userId), where('quizId', '==', quizId));
            const snapshot = await getDocs(q);

            if (!snapshot.empty) {
                // Dacă există un rezultat, verificăm scorul
                let docId = null;
                let currentScore = null;

                snapshot.forEach(doc => {
                    docId = doc.id;
                    currentScore = doc.data().score;
                });

                if (newScore > currentScore) {
                    // Dacă noul scor este mai mare, actualizăm documentul
                    const docToUpdate = doc(db, 'quizResults', docId);
                    await updateDoc(docToUpdate, { score: newScore });
                }
            } else {
                // Dacă nu există un rezultat, adăugăm unul nou
                await addDoc(quizResultsRef, {
                    userId: userId,
                    quizId: quizId,
                    score: newScore
                });
            }
            return { success: true };
        } catch (error) {
            console.error("Error saving quiz result:", error);
            return { success: false, error: error.message };
        }
    }, []);

    return { saveQuizResultToFirebase };
};

export default useSaveQuizResult;