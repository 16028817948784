import React, {useState} from 'react';
import {Container, Snackbar, Alert} from '@mui/material';
import {useAddResource} from "../../hooks/resources/UseAddResource";
import AddPostForm from "../../components/admin/AddPostForm";

const AddPostPage = () => {
    const [isVideo, setIsVideo] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState(null);
    const [videoLink, setVideoLink] = useState('');
    const {addPost, loading, error} = useAddResource();
    const [postAdded, setPostAdded] = useState(false);
    const [selectedClasses, setSelectedClasses] = useState({});

    const handleAddPost = async () => {
        const result = await addPost(title, description, file, isVideo ? 'video' : 'scris', videoLink, selectedClasses);
        if (result.success) {
            setPostAdded(true);
            setTitle('');
            setDescription('');
            setFile(null);
            setVideoLink('');
            setSelectedClasses({}); // resetează și clasele selectate
        }
    };

    return (
        <Container>
            <AddPostForm postAdded={postAdded} {...
                {
                    isVideo, setIsVideo, title, setTitle, description, setDescription, file,
                    setFile, videoLink, setVideoLink, onSubmit: handleAddPost,selectedClasses,setSelectedClasses
                }}/>

            {postAdded && (
                <Snackbar open onClose={() => setPostAdded(false)} autoHideDuration={6000}>
                    <Alert severity="success">Postare adăugată!</Alert>
                </Snackbar>
            )}

            {error && (
                <Snackbar open autoHideDuration={6000}>
                    <Alert severity="error">{error.message}</Alert>
                </Snackbar>
            )}
        </Container>
    );
};

export default AddPostPage;
