import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box,
    CircularProgress,
    Container,
    IconButton,
    Paper,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import ResourcesList from "../components/resources/ResourceList";
import IntroductionSection from "../components/home/InstructionSection";
import useLimitedResources from "../hooks/resources/UseLimitedResources";
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import Link from '@mui/material/Link';
const HomePage = () => {
    const { resources, error, isLoading } = useLimitedResources();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        const auth = getAuth();
        return onAuthStateChanged(auth, user => {
            setIsAuthenticated(!!user);
        });
    }, []);

    useEffect(()=>(console.log("is loading",isLoading)),[isLoading]);

    if (isAuthenticated === null) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (!isAuthenticated) {
        return (
            <>
                <Box bgcolor="primary.main" color="white" py={isMobile ? 2 : 3} boxShadow={3}>
                    <Container>
                        <Box display="flex" alignItems="center" justifyContent="center" flexDirection={isMobile ? 'column' : 'row'} mb={4}>
                            <Avatar src="Profil2.jpg" alt="MarinaGabriela" sx={{ width: 60, height: 60, mr: isMobile ? 0 : 2, mb: isMobile ? 2 : 0 }} />
                            <Typography
                                variant="h2"
                                align="center"
                                style={{
                                    fontSize: '1.5rem', // Setează dimensiunea dorită a fontului aici
                                    wordBreak: 'break-word',
                                    lineHeight: 1.2,
                                }}
                            >
                                Bun venit pe <b>gabrielamarina.com</b>!
                            </Typography>
                        </Box>
                        <Typography   variant="h2"
                                      align="center"
                                      style={{
                                          fontSize: '1.5rem', // Setează dimensiunea dorită a fontului aici
                                          wordBreak: 'break-word',
                                          lineHeight: 1.2,
                                      }}>
                            Explorează cele mai noi materiale de învățare și resurse pentru Limba și Literatura Română.
                        </Typography>
                    </Container>
                </Box>

                {/* Secțiunea de contact */}
                <Paper elevation={3} style={{ padding: '16px', marginTop: '16px' }}>
                    <Container>
                        <Typography variant="h6" mb={2}>Contact:</Typography>
                        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={2}>
                            <Box display="flex" alignItems="center">
                                <IconButton color="primary" aria-label="email">
                                    {/* Link către funcționalitatea de trimitere email */}
                                    <Link href="mailto:gabrielamarina2003@yahoo.fr" color="inherit">
                                        <EmailIcon />
                                    </Link>
                                </IconButton>
                                {/* Link către funcționalitatea de trimitere email */}
                                <Link href="mailto:gabrielamarina2003@yahoo.fr" color="inherit">
                                    <Typography>gabrielamarina2003@yahoo.fr</Typography>
                                </Link>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <IconButton color="primary" aria-label="phone">
                                    <Link href="tel:+40756714364" color="inherit">
                                        <PhoneIcon />
                                    </Link>
                                </IconButton>
                                <Link href="tel:+40756714364" color="inherit">
                                    <Typography>+40 756 714 364</Typography>
                                </Link>
                            </Box>
                        </Box>
                    </Container>
                </Paper>
            </>
        )
    }

    if (error) {
        return (
            <Box>
                <IntroductionSection isMobile={isMobile} />
                <Box my={4}>
                    <Typography variant="h5" align="center" color="error.main">
                        {error}
                    </Typography>
                </Box>
            </Box>
        );
    }

    if (!error && !isLoading && resources.length === 0) {
        return (
            <Box>
                <IntroductionSection isMobile={isMobile} />
                <Box my={4}>
                    <Typography variant="h5" align="center">
                        Momentan nu există materiale disponibile 😊
                    </Typography>
                </Box>
            </Box>
        );
    }

    return (
        <Box>
            <IntroductionSection isMobile={isMobile} />

            <Box mt={6} py={6}>
                <Typography variant="h4" gutterBottom>
                    Materiale recente
                </Typography>
                <ResourcesList resources={resources} hideSearch={true}/>
            </Box>
        </Box>
    );
}

export default HomePage;
