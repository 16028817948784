import React, {useCallback, useEffect, useState} from 'react';
import { Button, CircularProgress, Typography, Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useSaveQuizResult from "../../../hooks/quiz/UseSaveQuizResult";

const ResultComponent = ({ score, totalQuestions, userId, quizId }) => {
    const history = useNavigate();
    const { saveQuizResultToFirebase } = useSaveQuizResult();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const percentageScore = (score / totalQuestions) * 10;
    const color = percentageScore > 5 ? 'primary' : 'secondary';
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleSaveResult = useCallback(async () => {
        const result = await saveQuizResultToFirebase(userId, quizId, percentageScore);
        if (result.success) {
            setSnackbarMessage("Rezultat salvat cu succes");
            setSnackbarSeverity("success");
        } else {
            setSnackbarMessage("Eroare la salvare: " + result.error);
            setSnackbarSeverity("error");
        }
        setSnackbarOpen(true);
        history('/teste');
    }, [saveQuizResultToFirebase, userId, quizId, score, history]);

    return (
        <div style={{ textAlign: 'center', marginTop: '2rem' }}>
            <Typography variant="h4">Nota ta:</Typography>
            <CircularProgress variant="determinate" value={percentageScore * 10} color={color} style={{ width: '120px', height: '120px', margin: '1rem auto' }} />
            <Typography variant="h3" style={{ marginBottom: '2rem' }}>{percentageScore.toFixed(1)}</Typography>
            <div>
                <Button variant="contained" color="primary" style={{ marginRight: '1rem' }} onClick={() => window.location.reload()}>
                    Reîncearcă!
                </Button>
                <Button variant="outlined" onClick={handleSaveResult}>
                    Trimite rezultatul!
                </Button>
            </div>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ResultComponent;
