import React, { useState } from 'react';
import { TextField } from '@mui/material';
import WritedResource from './WritedResource';
import VideoResource from './VideoResource';

const ResourcesList = ({  resources, isAdmin, onDelete, refreshList,hideSearch=false }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const handleDelete = async (title) => {
        const result = await onDelete(title);
    };

    // Filtrăm resursele în funcție de termenul de căutare
    const filteredResources = resources.filter(resource =>
        resource.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div>
            {/* Adăugăm un TextField de căutare deasupra listei de resurse */}
            {
                !hideSearch&&
            <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                label="Caută material..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
            />
            }
            {filteredResources.map((resource, index) => (
                <div key={index} style={{ marginBottom: '20px' }}>
                    {resource.type === 'written' && (
                        <WritedResource
                            {...resource}
                            isAdmin={isAdmin}
                            fileName={resource.fileName}
                            onDelete={handleDelete}
                        />
                    )}
                    {resource.type === 'video' && (
                        <VideoResource
                            {...resource}
                            isAdmin={isAdmin}
                            onDelete={handleDelete}
                        />
                    )}
                </div>
            ))}
        </div>
    );
}

export default ResourcesList;
