import { useCallback } from "react";
import { getFirestore, collection, where, query, getDocs } from "firebase/firestore";

const useGetTotalScore = () => {
    const getTotalScoreForUser = useCallback(async (userId) => {
        try {
            const db = getFirestore();
            const quizResultsRef = collection(db, 'quizResults');

            const q = query(quizResultsRef, where('userId', '==', userId));
            const snapshot = await getDocs(q);

            let totalScore = 0;

            snapshot.forEach(doc => {
                totalScore += doc.data().score;
            });

            return { success: true, totalScore };

        } catch (error) {
            console.error("Error fetching total score:", error);
            return { success: false, error: error.message };
        }
    }, []);

    return { getTotalScoreForUser };
};

export default useGetTotalScore;
