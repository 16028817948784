import React, { useEffect, useState } from 'react';
import {
    Button, Checkbox, FormControlLabel, FormGroup, Typography, Card, CardContent, CardActions,
    CircularProgress, Snackbar, Alert, Box, Container
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const QuestionComponentForQuiz = ({ question, optionalText, onAnswerSelection, onNext, currentQuestionIndex, totalQuestions }) => {
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [randomOrder, setRandomOrder] = useState([]);

    useEffect(() => {
        const order = [...Array(question.options.length).keys()];
        order.sort(() => Math.random() - 0.5);
        setRandomOrder(order);
    }, [question]);

    const handleCheckboxChange = (e) => {
        const value = e.target.name;
        if (e.target.checked) {
            setSelectedAnswers(prev => [...prev, value]);
        } else {
            setSelectedAnswers(prev => prev.filter(answerIdx => answerIdx !== value));
        }
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleNextClick = () => {
        if (selectedAnswers.length === 0) {
            setShowAlert(true);
            return;
        }
        onAnswerSelection(selectedAnswers);
        onNext();
        setSelectedAnswers([]);
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    const progress = (currentQuestionIndex / totalQuestions) * 100;

    return (
        <Container maxWidth="md" style={{ padding: '10vh 20px 20px 20px' }}>
            <Box position="relative" alignSelf="center" marginBottom="20px">
                <CircularProgress variant="determinate" value={progress} style={{ color: '#3f51b5' }} />
                <Box position="absolute" top="50%" left="50%" style={{ transform: 'translate(-50%, -50%)', fontSize: '1.2rem', fontWeight: 'bold' }}>
                    {currentQuestionIndex}/{totalQuestions}
                </Box>
                <Dialog open={openDialog} onClose={handleCloseDialog}>
                    <DialogTitle>{optionalText}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {question.optionalText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">
                            Închide
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>

            <Card variant="outlined" style={{ flexGrow: 1, boxShadow: '0px 0px 25px rgba(0, 0, 0, 0.1)', borderRadius: '15px' }}>
                <CardContent>
                    <Typography variant="h4" gutterBottom style={{ fontSize: '1.5rem' }}>
                        {question.questionText}
                    </Typography>
                    <FormGroup>
                        {randomOrder.map(idx => (
                            <FormControlLabel
                                key={idx}
                                control={
                                    <Checkbox
                                        name={idx.toString()}
                                        color="primary"
                                        style={{ transform: 'scale(1.5)' }}
                                        checked={selectedAnswers.includes(idx.toString())}
                                        onChange={handleCheckboxChange}
                                    />
                                }
                                label={<Typography variant="h6">{question.options[idx]}</Typography>}
                                style={{ marginBottom: '20px' }}
                            />
                        ))}
                    </FormGroup>
                </CardContent>
                <CardActions style={{ justifyContent: 'space-between' }}>
                    {optionalText && (
                        <Button variant="outlined" color="primary" onClick={handleOpenDialog}>
                            Arată textul
                        </Button>
                    )}
                    <Button variant="contained" color="primary" size="large" endIcon={<ArrowForwardIosIcon />} onClick={handleNextClick}>
                        Următoarea
                    </Button>
                </CardActions>
            </Card>

            <Snackbar open={showAlert} autoHideDuration={4000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity="warning" variant="filled">
                    Selectează cel puțin un răspuns!
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default QuestionComponentForQuiz;
