import React from 'react';
import {Card, CardContent, Typography, Box, Avatar, Button} from '@mui/material';

const VideoResource = ({ title, description, classes, isAdmin, onDelete,fileUrl }) => {
    let videoId = "";
    try {
        videoId = new URL(fileUrl).searchParams.get("v");
    } catch (err) {
        console.error(`Eroare la parsarea URL-ului: ${fileUrl}`);
    }

    return (
        <Card style={{ position: "relative" }}>
            <CardContent style={{ paddingTop: 50 }}>
                <Box position="absolute" top={10} right={10} display="flex">
                    {classes.map((cls, index) => (
                        <Box ml={index !== 0 ? 1 : 0} key={cls}>
                            {/* Se descompune string-ul și se obține partea numerică */}
                            <Avatar style={{ width: 25, height: 25 }}>{cls.split(/[\s-]+/).find(item => !isNaN(item))}</Avatar>
                        </Box>
                    ))}
                </Box>
                <Typography variant="h6">{title}</Typography>
                <Typography color="textSecondary">{description}</Typography>
                <Box mt={2} style={{ maxWidth: 600, marginRight: 'auto' }}>
                    <Box position="relative" style={{ width: "100%", paddingBottom: "56.25%", height: 0 }}>
                        {videoId ? (
                            <iframe
                                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                                src={`https://www.youtube.com/embed/${videoId}`}
                                title={title}
                                frameborder="0"
                                allowfullscreen>
                            </iframe>
                        ) : (
                            <Typography color="error">Eroare la afișarea videoclipului</Typography>
                        )}
                    </Box>
                </Box>
                {isAdmin && (
                    <Button style={{margin:20}} variant="contained" color="secondary" onClick={() => onDelete(title)}>
                        Șterge
                    </Button>
                )}
            </CardContent>
        </Card>
    );
}

export default VideoResource;
