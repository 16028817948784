import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, getFirestore } from 'firebase/firestore';

const useGetUserName = (email) => {
    const [userName, setUserName] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserNameFromFirebase = async () => {
            try {
                if (!email) throw new Error('No email provided');

                const db = getFirestore();
                const userCollection = collection(db, 'users');
                const emailQuery = query(userCollection, where('email', '==', email));
                const querySnapshot = await getDocs(emailQuery);

                if (!querySnapshot.empty) {
                    const userData = querySnapshot.docs[0].data();
                    if (userData && userData.name) {
                        setUserName(userData.name);
                    } else {
                        throw new Error('Name field missing in user data');
                    }
                } else {
                    throw new Error('User not found');
                }
            } catch (fetchError) {
                console.error("Error fetching user name:", fetchError);
                setError(fetchError);
            } finally {
                setLoading(false);
            }
        };

        fetchUserNameFromFirebase();
    }, [email]);

    return { userName, loading, error };
};

export default useGetUserName;
