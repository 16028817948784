import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { checkIfUserIsAdmin } from '../../services/CheckIfUserIsAdmin';

export const useAuthCheck = () => {
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const auth = getAuth();

        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                const isAdminStatus = await checkIfUserIsAdmin(currentUser.email);
                setUser(currentUser);
                setIsAdmin(isAdminStatus);
            } else {
                setUser(null);
                setIsAdmin(false);
            }

            setIsLoading(false);
        });

        return () => unsubscribe();
    }, []);

    return { user, isAdmin, isLoading };
};