import React, { useState } from 'react';
import {Container, Snackbar, Alert, CircularProgress} from '@mui/material';
import AddHomeworkForm from "../../components/admin/AddHomeworkForm";
import { useUploadHomework } from "../../hooks/homeworks/UseUploadHomework";

const AddHomeworkPage = () => {
    const [homeworkTitle, setHomeworkTitle] = useState('');
    const [homeworkRequirement, setHomeworkRequirement] = useState('');
    const [homeworkImage, setHomeworkImage] = useState(null);
    const [grade, setGrade] = useState('');

    const { uploadHomework, loading, error } = useUploadHomework();
    const [homeworkAdded, setHomeworkAdded] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);

    const handleAddHomework = async () => {
        setLoadingButton(true);
        const result = await uploadHomework(homeworkTitle, homeworkRequirement, homeworkImage, grade);
        setLoadingButton(false);
        if (result.success) {
            setHomeworkAdded(true);
            setHomeworkTitle('');
            setHomeworkRequirement('');
            setHomeworkImage(null);
            setGrade('');
        }
    };

    return (
        <Container>
            <AddHomeworkForm
                {...{
                    title: homeworkTitle,
                    setTitle: setHomeworkTitle,
                    requirement: homeworkRequirement,
                    setRequirement: setHomeworkRequirement,
                    image: homeworkImage,
                    setImage: setHomeworkImage,
                    grade,
                    setGrade,
                    onSubmit: handleAddHomework,
                    loading: loadingButton // Adăugat această prop pentru a indica încărcarea
                }}
            />

            {loadingButton && <CircularProgress />}

            {homeworkAdded && (
                <Snackbar open onClose={() => setHomeworkAdded(false)} autoHideDuration={6000}>
                    <Alert severity="success">Temă adăugată!</Alert>
                </Snackbar>
            )}

            {error && (
                <Snackbar open autoHideDuration={6000}>
                    <Alert severity="error">{error.message}</Alert>
                </Snackbar>
            )}
        </Container>
    );
};

export default AddHomeworkPage;