import { useCallback } from "react";
import { getFirestore, collection, doc, deleteDoc, query, where, getDocs } from "firebase/firestore";

const useDeleteQuiz = () => {
    const deleteQuizFromFirebase = useCallback(async (quizId) => {
        try {
            const db = getFirestore();

            // Pasul 1: Stergerea rezultatelor quiz-ului
            const quizResultsRef = collection(db, 'quizResults');
            const q = query(quizResultsRef, where("quizId", "==", quizId));
            const querySnapshot = await getDocs(q);
            const deletePromises = [];
            querySnapshot.forEach((queryDoc) => {
                const resultRef = doc(db, 'quizResults', queryDoc.id);
                deletePromises.push(deleteDoc(resultRef));
            });

            await Promise.all(deletePromises);

            // Pasul 2: Stergerea quiz-ului
            const quizRef = doc(db, 'quizzes', quizId);
            await deleteDoc(quizRef);

            return { success: true };
        } catch (error) {
            console.error("Error deleting quiz and its results:", error);
            return { success: false, error: error.message };
        }
    }, []);

    return { deleteQuizFromFirebase };
};

export default useDeleteQuiz;
