// firebaseService.js

import { getFirestore, collection, getDocs } from 'firebase/firestore';

export const checkIfUserIsAdmin = async (userEmail) => {
    const db = getFirestore();
    const adminsCollection = collection(db, 'admins');

    const querySnapshot = await getDocs(adminsCollection);
    let isAdmin = false;

    querySnapshot.forEach((doc) => {
        if (doc.data().admin === userEmail) {
            isAdmin = true;
        }
    });

    return isAdmin;
};
